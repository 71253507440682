import { Alert, Button, Card, Col, Collapse, Dropdown, Form, Menu, Popconfirm, Row } from "antd";
import LeftDragTextComponent from "./LeftDragTextComponent";
import RightDragTextComponent from "./RightDragTextComponent";
import MobileViewDragComponent from "./MobileViewDragComponent";
import MobileViewTextComponent from "./MobileViewTextComponent";
import MobileViewTextDragComponent from "./MobileViewTextDragComponent";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { AccordionSvg, ButtonSvg, CoverSvg, H1Svg, H2Svg, H3Svg, H4Svg, H5Svg, ImageSvg, LinkSvg, UnorderedListSvg } from '../../assets/images/DragSvg';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { AddBox, DeleteSweep } from "@mui/icons-material";
import { FloatingLabel } from "react-bootstrap";
import { putDataRequest } from "../../api/serviceMethods";
import MessageConfirmModal from "../messageModal/MessageConfirmModal";
import { NEWS_GET_URL } from "../../api/URLs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ZoomScrollImage, { ZoomCornerScrollImage } from "../../assets/images/ZoomScrollImage";
import { CopyDeletComponent } from "./CopyDeletComponent";
import { CaretRightOutlined } from "@ant-design/icons";

const DragViewMobileComponent = ({
  datalocalize, setDatalocalize, loading, setLoading
}, ref) => {
  const { i18n, t } = useTranslation();
  const [currentEditLanguage, setCurrentEditingLanguage] = useState('cn');
  const [disabledDropzone, setDisabledDropzone] = useState(null);
  const [draggableId, setDraggableId] = useState("")
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeDroppable, setActiveDroppable] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false)
  const messageModalRef = useRef("");
  const navigate = useNavigate();

  let condeLang = "cn"//i18n.language ? i18n.language : "cn"
  let dataLang = datalocalize && datalocalize.localize && datalocalize.localize[condeLang] ? datalocalize.localize[condeLang] : null

  const [currentPost, setCurrentPost] = useState({
    "localize": {
      "cn": {
        "layout": dataLang && dataLang.layout ? dataLang.layout : [],
        // [
        //   {
        //     "label": "内容",
        //     "tag": "content",
        //     "text": "Hello",
        //     "textDecoration": "none",
        //     "marginTop": 0,
        //     "marginBottom": 0,
        //     "marginLeft": 0,
        //     "marginRight": 0,
        //     "textAlign": "center",
        //     "italicType": "italic",
        //     "index": 0,
        //     "fontWeight": "bold"
        // },
        // ],
        "title": dataLang && dataLang.title ? dataLang.title : "",
        "subtitle": dataLang && dataLang.subtitle ? dataLang.subtitle : ""
      },
      // "ca": {
      //   "layout": [],
      //   "title": "",
      //   "subtitle": ""
      // }
    }
  });
  let lang = 'cn'

  useEffect(() => {
    setCurrentPost({
      "localize": {
        "cn": {
          "layout": dataLang && dataLang.layout ? dataLang.layout : [],
          "title": dataLang && dataLang.title ? dataLang.title : "",
          "subtitle": dataLang && dataLang.subtitle ? dataLang.subtitle : ""
        },
      }
    })
  }, [dataLang])

  const [previewOpen, setPreviewOpen] = useState(false);
  const handlePreviewImage = async (url) => {
    setPreviewOpen(url);
  };

  const capitalizeFirstLetter = (str) => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : 'NA';
  };
  const styleCheck = (it) => {
    let styleNew = {
      textDecoration: it.textDecoration ? it.textDecoration : "",
      marginTop: it.marginTop ? `${it.marginTop}px` : "0px",
      marginBottom: it.marginBottom ? `${it.marginBottom}px` : "0px",
      marginLeft: it.marginLeft ? `${it.marginLeft}px` : "0px",
      marginRight: it.marginRight ? `${it.marginRight}px` : "0px",
      textAlign: it.textAlign ? it.textAlign : "",
      fontStyle: it.italicType ? it.italicType : "",
      height: it.height ? it.height : "",
      width: it.width ? it.width : "",
      fontWeight: it.fontWeight ? it.fontWeight : "",
    }
    return styleNew
  }
  const _RenderPreview = (items, indexList, elementId) => {
    if (!items) {
      return ''
    }
    let arr = []
    let i = 0
    let item = items ? items : {}
    let result = []
    let tag = item && item.tag ? item.tag : ''
    switch (tag) {
      case `content`:
        result.push(
          // <div style={{ marginBottom: item.marginBottom || 0, ...fontStyle(item, false) }}>{item.text}</div>
          <div className="w-100 content-cus-body"
            onClick={() => {
              setActiveIndex(`${elementId}`)
            }}
            style={styleCheck(item)}
          >
            <div style={styleCheck(item)}>{item.text ? item.text : "NA"}</div>
          </div>
        );
        break;
      case `bullet`:
        const lis = [
          <li
          // style={{ marginBottom: item.marginBottom || 0, ...fontStyle(item, false) }}
          >
            {capitalizeFirstLetter(item.text)}
          </li>,
        ];
        while (arr[i + 1]) {
          if (arr[i + 1].tag === 'bullet') {
            ++i;
            lis.push(
              <li
              // style={{ marginBottom: item.marginBottom || 0, ...fontStyle(item, false) }}
              >
                {capitalizeFirstLetter(item.text)}
              </li>
            );
          } else {
            break;
          }
        }
        result.push(<ul
          onClick={() => {
            setActiveIndex(`${elementId}`)
          }}
          style={styleCheck(item)} className="bullet-cus">{lis}</ul>);
        break;
      case `button`:
        result.push(
          <div className="w-10 button-cus"
            onClick={() => {
              setActiveIndex(`${elementId}`)
            }}
            style={{
              // textDecoration: item.textDecoration ? item.textDecoration : "",
              marginTop: item.marginTop ? `${item.marginTop}px` : "0px",
              marginBottom: item.marginBottom ? `${item.marginBottom}px` : "0px",
              marginLeft: item.marginLeft ? `${item.marginLeft}px` : "0px",
              marginRight: item.marginRight ? `${item.marginRight}px` : "0px",
              textAlign: item.textAlign ? item.textAlign : "",
              // fontStyle: item.italicType ? item.italicType : "",
              // height: item.height ? item.height : "",
              // width: item.width ? item.width : "",
            }}
          // style={styleCheck(item)}
          >
            {/* <Button style={styleCheck(item)}>{capitalizeFirstLetter(item.text)}</Button> */}
            <Button
              className='b-danger'
              type="primary"
              onClick={() => {

              }}
              style={{
                ...styleCheck(item),
                backgroundColor: "#E50A47",
                border: "1px solid #e72158",
              }}
              danger
            >
              {capitalizeFirstLetter(item.text)}
            </Button>
          </div>
        );
        break;
      case `h1`:
        result.push(
          <div
            onClick={() => {
              setActiveIndex(`${elementId}`)
            }}
            className="w-100 h1-div">
            <h1 className="h1" style={styleCheck(item)}>{capitalizeFirstLetter(item.text)}</h1>
          </div>
        );
        break;
      case `h2`:
        result.push(
          <div
            onClick={() => {
              setActiveIndex(`${elementId}`)
            }}
            className="w-100 h1=2-div">
            <h1 className="h2" style={styleCheck(item)}>{capitalizeFirstLetter(item.text)}</h1>
          </div>
        );
        break;
      case `h3`:
        result.push(
          <div
            onClick={() => {
              setActiveIndex(`${elementId}`)
            }}
            className="w-100 h3-div">
            <h1 className="h3" style={styleCheck(item)}>{capitalizeFirstLetter(item.text)}</h1>
          </div>
        );
        break;
      case `h4`:
        result.push(
          <div
            onClick={() => {
              setActiveIndex(`${elementId}`)
            }}
            className="w-100 h4-div">
            <h1 className="h4" style={styleCheck(item)}>{capitalizeFirstLetter(item.text)}</h1>
          </div>
        );
        break;
      case `h5`:
        result.push(
          <div
            onClick={() => {
              setActiveIndex(`${elementId}`)
            }}
            className="w-100 h5-div">
            <h1 className="h5" style={styleCheck(item)}>{capitalizeFirstLetter(item.text)}</h1>
          </div>
        );
        break;
      case `link`:
        result.push(
          <div
            onClick={() => {
              setActiveIndex(`${elementId}`)
            }}
            className="w-100 link-div" style={styleCheck(item)}>
            <a className="link-mobile" style={styleCheck(item)}>{capitalizeFirstLetter(item.text)}</a>
          </div>
        );
        break;
      case `image`:
        result.push(
          <div
            onClick={() => {
              setActiveIndex(`${elementId}`)
            }}
            className="w-100 image-div"
            style={{ ...styleCheck(item), cursor: "pointer" }}
          >
            <img
              onClick={() => handlePreviewImage(item.url)}
              src={item.url}
              alt={item.url}
              style={{ ...styleCheck(item), cursor: "pointer" }}
              className="image-mobile"
            />
          </div>
        );
        break;
      case `accordion`:
        const children = _RenderPreview(item.accordion, indexList, elementId);
        const rgn = Math.random();
        const wrapper = (
          <div className="mock-accordion">
            <div>
              <input type="checkbox" id={'section' + rgn} className="mock-accordion-input" />
              <label htmlFor={'section' + rgn} className="mock-accordion-label">
                {item.text}
              </label>
              <div className="mock-accordion-content">{children}</div>
            </div>
          </div>
        );
        result.push(<div
          onClick={() => {
            setActiveIndex(`${elementId}`)
          }}
          className="w-100">
          <div className="w-100" style={styleCheck(item)}>
            {wrapper}
          </div>
        </div>);
        break;

      default:
        break;
    }
    return result
  }


  const accordionDroppableStyle = (isDraggingOver, indexList) => {
    return {
      minHeight: '150px',
      padding: '5px',
      border: isDraggingOver.isDraggingOver ? '2px dashed lightgreen' : 'none',
      background: indexList.length % 2 !== 0 ? 'rgba(128, 128, 128, 0.08)' : 'rgba(128, 128, 128, 0.24)',
    };
  };
  const contentDroppableStyle = (isDraggingOver) => ({
    minHeight: '312px',
    padding: '10px',
    border: isDraggingOver ? '4px dashed lightgreen' : 'none',
  });
  const contentDraggableStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    marginBottom: 5,
  });

  const contentCardHeadRender = (content, elementId) => {
    let result;
    switch (content.tag.toLowerCase()) {
      case 'bullet':
      case 'content':
        result = (
          <select
            onChange={(e) => {
              content.tag = e.target.value;
              setCurrentPost({ ...currentPost });
            }}
          >
            <option value="bullet" selected={content.tag.toLowerCase() === 'bullet'}>
              Bullet
            </option>
            <option value="content" selected={content.tag.toLowerCase() === 'content'}>
              Body
            </option>
          </select>
        );
        break;
      case 'h1':
      case 'h2':
      case 'h3':
      case 'h4':
      case 'h5':
        result = (
          <select
            onChange={(e) => {
              content.tag = e.target.value;
              setCurrentPost({ ...currentPost });
            }}
          >
            <option value="h1" selected={content.tag.toLowerCase() === 'h1'}>
              H1
            </option>
            <option value="h2" selected={content.tag.toLowerCase() === 'h2'}>
              H2
            </option>
            <option value="h3" selected={content.tag.toLowerCase() === 'h3'}>
              H3
            </option>
            <option value="h4" selected={content.tag.toLowerCase() === 'h4'}>
              H4
            </option>
            <option value="h5" selected={content.tag.toLowerCase() === 'h5'}>
              H5
            </option>
          </select>
        );
        break;
      case 'button':
        result = <span>Button</span>;
        break;
      case 'link':
        result = <span>Link Button</span>;
        break;
      case 'img':
        result = <span>Image</span>;
        break;
      case 'image':
        result = <span>Image</span>;
        break;
      case 'accordion':
        result = <div className="w-100"><span className="w-100 d-block"
          onClick={() => {
            setActiveIndex(`${elementId}`)
          }}
        // style={styleCheck(content)}
        >{content.text || "NA"}</span></div>;
        break;
    }

    return (
      <>
        {result}
      </>
    );
  };

  const addToAccordion = (content, type) => {
    const newEl = { ...types.find((t) => t.tag === type).default };
    content.accordion = [...content.accordion, newEl];
    setCurrentPost({ ...currentPost });
  };
  const handleCopy = (contents, content, currentIndex) => {
    contents.splice(currentIndex, 0, { ...content });
    setCurrentPost({ ...currentPost });
  };
  const handelDelete = (contents, content, currentIndex) => {
    contents.splice(currentIndex, 1);
    setCurrentPost({ ...currentPost });

  };


  const contentDroppableAreaRender = (lang, contents, indexList) => {
    return (
      <Droppable
        key={`${lang}-content-droppable@${indexList.join('-')}`}
        isDropDisabled={
          disabledDropzone === 'types' ? false : disabledDropzone !== `${lang}-content-droppable@${indexList.join('-')}`
        }
        droppableId={`${lang}-content-droppable@${indexList.join('-')}`}
      >
        {(dropProvided, dropSnapshot) => (
          <div
            ref={dropProvided.innerRef}
            style={
              indexList.length
                ? accordionDroppableStyle(dropSnapshot, indexList)
                : contentDroppableStyle(dropSnapshot.isDraggingOver)
            }
            {...dropProvided.droppableProps}

          >
            {contents.map((content, cIndex) => (
              <Draggable
                key={`${lang}-content-draggable@${indexList.join("-")}-${cIndex}`}
                draggableId={`${lang}-content-draggable@${indexList.join("-")}-${cIndex}`}
                index={cIndex}

              // key={`${lang}-content-draggable@${indexList.join('-')}-${cIndex}`}
              // draggableId={`${lang}-content-draggable@${indexList.join('-')}-${cIndex}`}
              // index={cIndex}
              >
                {(dragProvided, dragSnapshot) => (
                  <div
                    ref={dragProvided.innerRef}
                    {...dragProvided.draggableProps}
                    {...dragProvided.dragHandleProps}
                    style={{
                      ...contentDraggableStyle(dragSnapshot.isDragging, dragProvided.draggableProps.style),
                      border: activeIndex === `${lang}-content-draggable@${indexList.join("-")}-${cIndex}` ? "2px solid #86b7fe" : "none",
                      borderRadius: 4,
                    }}
                  // onClick={() => {
                  //   setActiveIndex(`${lang}-content-draggable@${indexList.join("-")}-${cIndex}`)
                  // }}
                  >
                    <div
                      className="drag-div w-100 d-flex- justify-content-between- align-items-center-"
                      style={{ position: "relative" }}
                    >
                      {content.tag.toLowerCase() === 'accordion' ? (
                        <div className="w-100 div-cus-collab"
                        // onClick={() => {
                        //   setActiveIndex(`${lang}-content-draggable@${indexList.join("-")}-${cIndex}`)
                        // }}
                        >
                          {/* according */}
                          <Collapse defaultActiveKey={['accordion']}
                            style={styleCheck(content)}
                          >
                            <Collapse.Panel
                              header={
                                <div
                                  className={`custom-header-class Collapse-Panel-cus ${lang}-content-draggable`}
                                  onClick={() => {
                                    setActiveIndex(`${lang}-content-draggable@${indexList.join("-")}-${cIndex}`)
                                  }}
                                >
                                  {
                                    contentCardHeadRender(content,
                                      `${lang}-content-draggable@${indexList.join("-")}-${cIndex}`
                                    )
                                  }
                                </div>

                              }
                              expandIcon={({ isActive }) => (
                                <span
                                  className="span-icon"
                                >
                                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                                </span>
                              )}
                              key="accordion"
                            // extra={
                            //   <Popconfirm
                            //     title={`Delete this accordion and all its children?`}
                            //   onConfirm={() => deleteContent(contents, cIndex)}
                            //   onCancel={(e) => e.stopPropagation()}
                            //   >
                            //     <Button
                            //       type="danger"
                            //       icon={<DeleteSweep />}
                            //       shape="circle"
                            //       size="small"
                            //       onClick={(e) => e.stopPropagation()}
                            //     />
                            //   </Popconfirm>
                            // }
                            >
                              <Row>
                                <Col span={24}>
                                  <FloatingLabel
                                    controlId={`${lang}-content-text-${indexList && indexList.join('-')}`}
                                    // label={`Header`}
                                    className="mb-1"
                                  >
                                  </FloatingLabel>
                                </Col>
                              </Row>
                              {types
                                .find((t) => t.tag === content.tag)
                                .render(lang, content.accordion, [...indexList, cIndex],
                                  `${lang}-content-draggable@${indexList.join("-")}-${cIndex}`
                                )}
                              <Dropdown
                                overlay={() => (
                                  <Menu>
                                    {types.map((type) => (
                                      <Menu.Item key={type.tag} onClick={({ key }) => addToAccordion(content, key)}>
                                        <div className="d-flex align-items-center justify-content-evenly">
                                          <span className="cus-selecte-svg me-2">{type.icon}</span>
                                          {t(type.text)}
                                        </div>
                                      </Menu.Item>
                                    ))}
                                  </Menu>
                                )}
                                trigger={['click']}
                              >
                                <span>{t("Add New")}</span>
                              </Dropdown>
                            </Collapse.Panel>
                          </Collapse>
                        </div>
                      ) : (
                        <div className="w-100 "
                        // onClick={() => {
                        //   setActiveIndex(`${lang}-content-draggable@${indexList.join("-")}-${cIndex}`)
                        // }}
                        >
                          {types.find((t) => t.tag === content.tag)?.render(lang, content, [...indexList, cIndex],
                            `${lang}-content-draggable@${indexList.join("-")}-${cIndex}`
                          )}
                        </div>
                      )}
                      {activeIndex === `${lang}-content-draggable@${indexList.join("-")}-${cIndex}` ? <div style={{ left: "-6px", top: "-14px", position: "absolute" }}>
                        <ZoomCornerScrollImage />
                      </div> : ""}
                      {activeIndex === `${lang}-content-draggable@${indexList.join("-")}-${cIndex}` ? <div style={{ left: "-6px", bottom: "-10px", position: "absolute" }}>
                        <ZoomCornerScrollImage />
                      </div> : ""}
                      {activeIndex === `${lang}-content-draggable@${indexList.join("-")}-${cIndex}` ? <div style={{ right: "-6px", top: "-14px", position: "absolute" }}>
                        <ZoomCornerScrollImage />
                      </div> : ""}
                      {activeIndex === `${lang}-content-draggable@${indexList.join("-")}-${cIndex}` ? <div style={{ right: "-6px", bottom: "-10px", position: "absolute" }}>
                        <ZoomCornerScrollImage />
                      </div> : ""}
                      {activeIndex === `${lang}-content-draggable@${indexList.join("-")}-${cIndex}` ? <div
                        // style={{ right: "-8px", position: "absolute" }}
                        className="zoom-cus"
                      >
                        <ZoomScrollImage />
                      </div> : ""}
                    </div>
                    {activeIndex === `${lang}-content-draggable@${indexList.join("-")}-${cIndex}` ? <CopyDeletComponent
                      handelCopy={() => {
                        handleCopy(contents, content, cIndex)
                      }}
                      handelDelete={() => {
                        handelDelete(contents, content, cIndex)
                      }}
                    /> : ""}
                  </div>
                )}
              </Draggable>
            ))}
            {dropProvided.placeholder}
          </div>
        )}
      </Droppable>
    )
  };

  const h1Render = (lang, content, indexList, elementId) => {

    return (
      <>
        {_RenderPreview(content, indexList, elementId)}
      </>
    )
  };
  const h2Render = (lang, content, indexList, elementId) => {
    return (
      <>
        {_RenderPreview(content, indexList, elementId)}
      </>
    )
  };
  const h3Render = (lang, content, indexList, elementId) => {
    return (
      <>
        {_RenderPreview(content, indexList, elementId)}
      </>
    )
  };
  const h4Render = (lang, content, indexList, elementId) => {
    return (
      <>
        {_RenderPreview(content, indexList, elementId)}
      </>
    )
  };
  const h5Render = (lang, content, indexList, elementId) => {
    return (
      <>
        {_RenderPreview(content, indexList, elementId)}
      </>
    )
  };
  const _Render = (lang, content, indexList, elementId) => {
    return (
      <>
        {_RenderPreview(content, indexList, elementId)}
      </>
    )
  };
  const _RenderContent = (lang, content, indexList, elementId) => {

    return (
      <>
        {_RenderPreview(content, indexList, elementId)}
      </>
    )
  };
  const imageRender = (lang, content, indexList, elementId) => {
    return (
      <>
        {_RenderPreview(content, indexList, elementId)}
      </>
    )
  };


  const types = [
    {
      text: 'Body',
      tag: 'content',
      icon: <CoverSvg />,
      render: _RenderContent,
      default: {
        tag: 'content',
        index: -1,
        fontWeight: 'regular',
        size: 'regular',
        textDecoration: 'none',
        marginBottom: 0,
        "height": "",
        "width": "",
      },
    },
    {
      text: 'Bullet',
      tag: 'bullet',
      icon: <UnorderedListSvg />,
      render: _Render,
      default: {
        tag: 'bullet',
        index: -1,
        fontWeight: 'regular',
        size: 'regular',
        textDecoration: 'none',
        marginBottom: 0,
        "height": "",
        "width": "",
      },
    },
    {
      text: 'Button',
      tag: 'button',
      icon: <ButtonSvg />,
      render: _Render,
      default: {
        tag: 'button',
        index: -1,
        navigation: {
          routerName: '',
          screenName: '',
          params: {},
        },
        title: '',
        marginBottom: 0,
        "height": "",
        "width": "",
      },
    },
    {
      text: 'H1',
      tag: 'h1',
      icon: <H1Svg />,
      render: h1Render,
      default: {
        tag: 'h1',
        index: -1,
        textDecoration: 'none',
        marginBottom: 0,
        "height": "",
        "width": "",
      },
    },
    {
      text: 'H2',
      tag: 'h2',
      icon: <H2Svg />,
      render: h2Render,
      default: {
        tag: 'h2',
        index: -1,
        textDecoration: 'none',
        marginBottom: 0,
        "height": "",
        "width": "",
      },
    },
    {
      text: 'H3',
      tag: 'h3',
      icon: <H3Svg />,
      render: h3Render,
      default: {
        tag: 'h3',
        index: -1,
        textDecoration: 'none',
        marginBottom: 0,
        "height": "",
        "width": "",
      },
    },
    {
      text: 'H4',
      tag: 'h4',
      icon: <H4Svg />,
      render: h4Render,
      default: {
        tag: 'h4',
        index: -1,
        textDecoration: 'none',
        marginBottom: 0,
        "height": "",
        "width": "",
      },
    },
    {
      text: 'H5',
      tag: 'h5',
      icon: <H5Svg />,
      render: h5Render,
      default: {
        tag: 'h5',
        index: -1,
        textDecoration: 'none',
        marginBottom: 0,
        "height": "",
        "width": "",
      },
    },
    {
      text: 'Link Button',
      tag: 'link',
      icon: <LinkSvg />,
      render: _Render,
      default: {
        tag: 'link',
        index: -1,
        link: '',
        title: '',
        marginBottom: 0,
        "height": "",
        "width": "",
      },
    },
    {
      text: 'Image',
      tag: 'image',
      icon: <ImageSvg />,
      render: imageRender,
      default: {
        tag: 'image',
        index: -1,
        url: '',
        ratio: 1,
        marginBottom: 0,
        "height": "",
        "width": "",
        imgwidth: 100,
      },
    },
    {
      text: 'Accordion',
      tag: 'accordion',
      icon: <AccordionSvg />,
      render: contentDroppableAreaRender,
      default: {
        tag: 'accordion',
        index: -1,
        header: '',
        accordion: [],
        marginBottom: 0,
        "height": "",
        "width": "",
      },
    },
  ];

  const reorder = (contents, startIndex, endIndex) => {
    const result = Array.from(contents);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const copyMove = (destination, droppableSource, droppableDestination) => {
    const destClone = Array.from(destination);
    const copying = { ...types[droppableSource.index].default };
    destClone.splice(droppableDestination.index, 0, copying);
    return destClone;
  };

  const onDragStart = (start) => {
    setDisabledDropzone(start.source.droppableId);
  };

  const onDragEnd = (result) => {

    try {

      setDisabledDropzone(null);
      const { source, destination } = result;

      // dropped outside the list
      if (!destination) {
        return;
      }
      const sInd = source.droppableId;
      const dInd = destination.droppableId;

      const droppingArea = dInd.split('@')[1];

      if (droppingArea === '') {
        if (sInd === dInd) {
          currentPost.localize[currentEditLanguage].layout = [
            ...reorder(currentPost.localize[currentEditLanguage]?.layout || [], source.index, destination.index),
          ];
        } else {
          currentPost.localize[currentEditLanguage].layout = [
            ...copyMove(currentPost.localize[currentEditLanguage]?.layout || [], source, destination),
          ];
        }
      } else {
        const positionList = droppingArea.split('-');
        let theDroppingArray = currentPost.localize[currentEditLanguage].layout[positionList[0]];
        for (let i = 1; i < positionList.length; ++i) {
          theDroppingArray = theDroppingArray.accordion[positionList[i]];
        }

        if (sInd === dInd) {
          theDroppingArray.accordion = [...reorder(theDroppingArray.accordion, source.index, destination.index)];
        } else {
          theDroppingArray.accordion = [...copyMove(theDroppingArray.accordion, source, destination)];
        }
      }

      let drop = `${destination.droppableId}`
      let dropNew = drop.replace("droppable", "draggable")
      setActiveIndex(`${dropNew}-${destination.index}`)

      setCurrentPost({ ...currentPost });
    } catch (error) {
      console.log(`error: `, error)

    }
  };

  const handleSubmitContent = async () => {
    const reassignIndex = (arr) => {
      arr.forEach((el, i) => {
        el.index = i;
        if (el.tag === 'accordion') {
          reassignIndex(el.accordion);
        }
      });
    };

    Object.values(currentPost.localize).forEach((content) => {
      reassignIndex(content.layout);
    });
    setIsSubmit(true)
    setLoading(true);
    try {
      const res = await putDataRequest(`${NEWS_GET_URL}/${datalocalize.newsId}`, currentPost);
      if (res?.status === 200) {
        messageModalRef.current.showSuccessConfirmsAutoClose(res.data?.message || t("Saved Successfully"), () => {
          navigate(`/news-posts`)
        }, "", true);
      } else {
        messageModalRef.current.showWarningConfirmsAutoClose(res.data?.message || res.message || t("An error occurred."), () => { }, "", true);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      messageModalRef.current.showWarningConfirmsAutoClose(err && err.response && err.response.data && err.response.data.message, () => { }, "", true);
    } finally {
      setLoading(false);
      setIsSubmit(false);
    }
  }

  useImperativeHandle(ref, () => {
    return { handleSubmitContent: handleSubmitContent }
  })

  let itemData = {}
  let droppingArea = ''
  let positionList = []
  if (currentPost && currentPost.localize && currentPost.localize[lang] && currentPost.localize[lang].layout) {
    // currentPost.localize[lang].layout
  }
  if (activeIndex) {
    droppingArea = activeIndex.split('@')[1];
    if (droppingArea === '') {
    }
    else {
      positionList = droppingArea.split('-');
      if (positionList[0] === "") {
        itemData = currentPost.localize[lang].layout[positionList[1]]
      } else {
        let theDroppingArray = currentPost.localize[currentEditLanguage].layout[positionList[0]];
        for (let i = 1; i < positionList.length; ++i) {
          theDroppingArray = theDroppingArray.accordion[positionList[i]];
        }
        itemData = theDroppingArray
      }
    }
  }

  return <>
    <div className="w-100 h-100">
      <div className="row w-100">
        {/* <Button onClick={() => {
          // if (positionList[0] === "") {
          //   itemData.text = "text"
          //   currentPost.localize[lang].layout[positionList[1]] = itemData
          // }
          // setCurrentPost({ ...currentPost });
          handleSubmitContent()
        }}>currentPost</Button> */}
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <div className="col-3 pe-4 ps-4 pt-2 pb-2" style={{ background: "#ffffff" }}>
            <LeftDragTextComponent
              layout={currentPost.localize && currentPost.localize[lang] && currentPost.localize[lang].layout ? currentPost.localize[lang].layout : []}
              types={types}
              activeIndex={activeIndex} setActiveIndex={setActiveIndex}
              lang={lang}
            />
          </div>
          <div className="col-6 pe-4 ps-4" style={{ overflow: "auto" }}>
            <div className='mt-3 w-100 mb-3'>
              <MobileViewTextDragComponent
                contentDroppableAreaRender={contentDroppableAreaRender(lang, currentPost.localize[lang].layout, [])}
                data={datalocalize ? datalocalize : null}
                activeIndex={activeIndex} setActiveIndex={setActiveIndex}
                lang={lang}
              />
            </div>
            {/* <div className='mt-3 w-100 mb-3'>
                        <MobileViewDragComponent />
                    </div> */}
          </div>
        </DragDropContext>
        <div className="col-3 pe-4 ps-4 pt-4 pb-4">
          <RightDragTextComponent
            itemData={itemData ? itemData : {}}
            setItemData={(item) => {
              if (positionList[0] === "") {
                currentPost.localize[lang].layout[positionList[1]] = item
              }
              setCurrentPost({ ...currentPost });
            }}
            types={types}
            lang={lang}
          />
        </div>
      </div>
    </div>
    <MessageConfirmModal textCentered ref={messageModalRef} />
  </>
}
export default forwardRef(DragViewMobileComponent);