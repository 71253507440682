import React, { useEffect, useState } from "react";
import { Button, Input } from "antd";
import { PAYMENT_URL } from "../../api/URLs";
import { getDataRequest } from "../../api/serviceMethods";
import TableFetchData from "../../components/data-table/TableFetchData";
import { useTranslation } from "react-i18next";
import DateComponent from "../../components/date/DateComponent";
import AirlyPaySvg from "../../assets/images/AirlyPay";
import WeChatSvg from "../../assets/images/WeChat";
import SelectComponent from "../../components/select/SelectComponent";
import { formatDateTime } from "../../utils/dateFormatUtils";
import DateRangeComponent from "../../components/date/DateRangeComponent";
import { SearchOutlined } from "@mui/icons-material";
import SearchSvg from "../../assets/images/SearchSvg";

const TablePayment = ({ userDetailId }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [memberData, setMemberData] = useState([]);
    const [dates, setDates] = useState([null, null]);
    const [keyword, setKeyword] = useState('');
    const [isSearch, setIsSearch] = useState(true)
    const [dataObject, setDataObject] = useState({
        type: "",
        portal: ""
    })
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        },
    });

    const initLoadPayment = async () => {
        setLoading(true);
        await getDataRequest(`${PAYMENT_URL}`, {
            keyword: keyword.trim(),
            startDate: dates[0] || "",
            endDate: dates[1] || "",
            paymentType: dataObject.type,
            paymentMethod: dataObject.method,
            pageNo: tableParams.pagination.current,
            pageSize: tableParams.pagination.pageSize,
            sortKeys: { [tableParams.sortField]: tableParams.sortOrder === "ascend" ? "asc" : "desc" },//descend
            memberId: userDetailId && userDetailId
        }).then(res => {
            setLoading(false);
            if (res.status === 200) {
                setMemberData(res?.data);
                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: res.total,
                    },
                });
            }
        }).catch((err) => {
            setLoading(false);
            console.log("Error", err);
        });
    };

    useEffect(() => {
        isSearch && initLoadPayment();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        tableParams?.sortOrder,
        tableParams?.sortField,
        JSON.stringify(tableParams.filters), isSearch, userDetailId
    ]);

    const tableSettings = {
        appendable: false,
        removable: false,
        bordered: true,
        size: "small",
        pagination: {
            showLessItems: true,
            showSizeChanger: false,
            pageSize: 10,
          },
        columns: [
            {
                title: <label className="titleCard14400">{t('Order Number')}</label>,
                render: (_, record) => (
                    <label className="text-capitalize">{record && record.paymentId}</label>
                ),
            },
            {
                title: <label className="titleCard14400">{t('Payment Amount')}</label>,
                render: (_, record) => (
                    <label className="text-capitalize">{`￥${record && record.amount}`}</label>
                ),
            },
            {
                title: <label className="ant-table-column-title titleCard14400">{t('Payment Time')}</label>,
                dataIndex: 'time',
                sorter: true,
                key: 'time',
                render: (_, record) => (
                    <>
                        {record.time ? formatDateTime(record.time) : ""}
                    </>
                )
            },
            {
                title: <label className="titleCard14400">{t('Payment Type')}</label>,
                render: (_, record) => (
                    <label className="text-capitalize">
                        {t(record && record.type)}
                    </label>
                ),
            },
            {
                title: <label className="titleCard14400">{t('Payment Portal')}</label>,
                render: (_, record) => {
                    if (record && record.method === 'alipay') {
                        return <AirlyPaySvg />;
                    } else if (record && record.method === 'wechatpay') {
                        return <WeChatSvg />;
                    } else {
                        return (
                            <label className="text-capitalize">
                                {t(record && record.method)}
                            </label>
                        );
                    }
                },
            }
        ],
    };

    const paymentTypeOpt = [
        {
            label: t("activate"),
            value: "activate"
        },
        {
            label: t("renewal"),
            value: "renewal"
        },
        {
            label: t("upgrade"),
            value: "upgrade"
        }
    ]

    const paymentMethodOpt = [
        {
            label: t("alipay"),
            value: "alipay"
        },
        {
            label: t("wechatpay"),
            value: "wechatpay"
        },
        {
            label: t("bank"),
            value: "bank"
        },
        {
            label: t("card"),
            value: "card"
        },
        {
            label: t("manual"),
            value: "manual"
        }
    ]

    return (
        <>
            <div className="w-100" style={{ padding: "0px 0px" }}>
                <div className="d-flex justify-content-between mb-3">
                    <div className="d-flex align-items-center">
                        <Input
                            placeholder={t("Search")}
                            prefix={<SearchSvg style={{ color: '#8c8c8c' }} />}
                            size="large"
                            className='me-2 col-4'
                            disabled={loading}
                            loading={loading}
                            allowClear
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                        />
                        <DateRangeComponent
                            className="col-4 me-2"
                            placeholder={t("Please select a time or time period")}
                            disabled={loading}
                            value={dates ? dates : [null, null]}
                            setValue={setDates}
                            style={{ width: "25%" }}
                        />
                        <div className="col-2 me-2">
                            <SelectComponent
                                // width={"150px"}
                                disabled={loading}
                                options={paymentTypeOpt}
                                value={dataObject.type}
                                setValue={(value) => setDataObject({ ...dataObject, type: value })}
                                placeholder={t('Payment Type')}
                            />
                        </div>
                        <div className="col-2">
                            <SelectComponent
                                className=""
                                // width={"150px"}
                                options={paymentMethodOpt} value={dataObject.method}
                                setValue={(value) => setDataObject({ ...dataObject, method: value })}
                                placeholder={t("Payment Portal")}
                                disabled={loading}
                            />
                        </div>
                    </div>
                    <div>
                        <Button
                            size="large"
                            onClick={() => {
                                setTableParams({
                                    ...tableParams,
                                    pagination: {
                                        ...tableParams.pagination,
                                        current: 1,
                                    },
                                });
                                setIsSearch(false)
                                setTimeout(() => {
                                    setIsSearch(true)
                                }, 100);
                            }}
                        >
                            {t('Query')}
                        </Button>

                    </div>
                </div>
            </div>
            <div className="w-100" style={{ padding: "0px 0px" }}>
                <TableFetchData
                    tableParams={tableParams}
                    setTableParams={setTableParams}
                    data={memberData}
                    columns={tableSettings.columns}
                    loading={loading}
                    setLoading={setLoading}
                />
            </div>
        </>
    );
};

export default TablePayment;
