import React from "react";
import { Avatar, Button, Col, Input, Row } from "antd";
import TableFetchData from "../../components/data-table/TableFetchData";
import { useTranslation } from "react-i18next";
import CorrectSvg from "../../assets/images/CorrectSvg";
import DateRangeComponent from "../../components/date/DateRangeComponent";
import emptyImage from "../../assets/images/image.jpeg"
import { formatDateTime } from "../../utils/dateFormatUtils";
import SearchSvg from "../../assets/images/SearchSvg";

const TableUser = ({
    loading,
    setLoading,
    memberData,
    keyword,
    setKeyword,
    tableParams,
    setTableParams,
    dates,
    setDates,
    initLoadMember,
    handleClickEdit
}) => {
    const { t } = useTranslation();

    const tableSettings = {
        appendable: false,
        removable: false,
        bordered: true,
        size: "small",
        pagination: {
            showLessItems: true,
            pageSize: 10,
        },
        columns: [
            {
                title: <label className="titleCard14400">{t('User Avatar')}</label>,
                render: (_, record) => (
                    <Avatar
                        src={record && record.picUrl ? record.picUrl : emptyImage}
                        style={{
                            height: "35px",
                            width: "35px"
                        }}
                    />
                ),
            },
            {
                title: <label className="titleCard14400">{t('User Id')}</label>,
                render: (_, record) => (
                    <label className="text-capitalize">{record && record.memberId}</label>
                ),
            },
            {
                title: "",
                render: (_, record) => (
                    <Row align="middle">
                        {record.memberType === "trial" ?
                            <>
                                <Col>
                                    <CorrectSvg style={{ marginRight: 8 }} />
                                </Col>
                                <Col className="text-capitalize">{t("Membership trial")}</Col>
                            </> : ""
                        }
                    </Row>
                ),
            },
            {
                title: <label className="titleCard14400">{t(t('Phone Number'))}</label>,
                render: (_, record) => (
                    <div className="d-flex">
                        <label className="text-capitalize">
                            {`(${record && record.phone && record.phone.countryCode})`} {record && record.phone && record.phone.number}
                        </label>
                    </div>
                ),
            },
            {
                title: <label className="ant-table-column-title titleCard14400">{t('Register Time')}</label>,
                dataIndex: 'createdTS',
                sorter: true,
                key: 'createdTS',
                render: (_, record) => (
                    <>
                        {record.createdTS ? formatDateTime(record.createdTS) : ""}
                    </>
                )
            },
            {
                title: <label className="ant-table-column-title titleCard14400">{t('Last Login Time')}</label>,
                dataIndex: 'lastLogin',
                sorter: true,
                key: 'lastLogin',
                render: (_, record) => (
                    <>
                        {record.lastLogin ? formatDateTime(record.lastLogin) : ""}
                    </>
                )
            },
            {
                title: <label className="titleCard14400">{t('Operator')}</label>,
                render: (_, record) => (
                    <div className="d-flex" style={{ cursor: "pointer", color: "#0075ff" }} onClick={() => handleClickEdit(record)}>
                        {t("Details")}
                    </div>
                ),
            },
        ],
    };

    return (
        <>
            <div className="w-100" style={{ padding: "0px 25px" }}>
                <div className="d-flex justify-content-between mb-3">
                    <div className="d-flex align-items-center">
                        <Input
                            placeholder={t("Search")}
                            prefix={<SearchSvg style={{ color: '#8c8c8c' }} />}
                            size="large"
                            disabled={loading}
                            loading={loading}
                            className="me-4 col-6"
                            allowClear
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                        />
                        <DateRangeComponent
                            className="col-6"
                            placeholder={t("Please select a time or time period")}
                            disabled={loading}
                            value={dates ? dates : [null, null]}
                            setValue={setDates}
                            style={{ width: "50%" }}
                            size="large"
                        />
                    </div>
                    <div>
                        <Button
                            size="large"
                            onClick={() => {
                                setTableParams({
                                    ...tableParams,
                                    pagination: {
                                        ...tableParams.pagination,
                                        current: 1,
                                    },
                                });
                                initLoadMember();
                            }}
                        >
                            {t('Query')}
                        </Button>

                    </div>
                </div>
            </div>
            <div className="w-100" style={{ padding: "0px 25px" }}>
                <TableFetchData
                    tableParams={tableParams}
                    setTableParams={setTableParams}
                    data={memberData}
                    columns={tableSettings.columns}
                    loading={loading}
                    setLoading={setLoading}
                />
            </div>
        </>
    );
};

export default TableUser;
