import { Button, Card, Col, Row, Switch, Tooltip } from "antd";
import React from "react";
import EditTableSvg from "../../assets/images/EditTable";
import DeleteTableSvg from "../../assets/images/DeleteTable";
import ViewTableSvg from "../../assets/images/ViewTable";
import TagTextComponent from "../tag/TagTextComponent";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const NewPostCard = ({ dataSource = [],
  handleStatus,
  handleDelete,
  handleView,
 }) => {
  let magazines = dataSource
  const navigate = useNavigate();

  const { i18n, t } = useTranslation();
  let condeLang = "cn"//i18n.language ? i18n.language : "cn"
  const checktextByLang = (dataCode) => {
    let text = dataCode && dataCode[condeLang] && dataCode[condeLang].title ? dataCode[condeLang].title : ''
    const languages = dataCode ?  Object.keys(dataCode) : []
    if (!text) {
      for (let lang of languages) {
        if (dataCode && dataCode[lang] && dataCode[lang].title) {
          text = dataCode[lang].title;
          break;
        }
      }
    }
    return text
  }
  const checktextByLangSub = (dataCode) => {
    let text = dataCode && dataCode[condeLang] && dataCode[condeLang].subtitle ? dataCode[condeLang].subtitle : ''
    const languages = dataCode ?  Object.keys(dataCode) : []
    if (!text) {
      for (let lang of languages) {
        if (dataCode && dataCode[lang] && dataCode[lang].subtitle) {
          text = dataCode[lang].subtitle;
          break;
        }
      }
    }
    return text
  }

  return (
    <>
      <Row
       gutter={{
        xs: [25, 25],
        sm: [25, 25],
        md: [25, 25],
        lg: [25, 25],
      }}
      className="justify-content-between-"
      >
        {magazines.map((item, index) => (
          <Col className="p-1" >
            <Card
              className="cusCard h-100"
              key={index}
              cover={<img className="image-mobile-150" alt={item.cover} src={item.cover} style={{ padding: "10px", borderRadius: "15px" }} />}
              actions={[
                <Switch checked={item.active} onChange={() => handleStatus(item)} />,
                <span onClick={() => {navigate(`/news/upload?id=${item.newsId}`)}}><EditTableSvg /></span>,
                <span onClick={() => handleDelete(item)}><DeleteTableSvg /></span>,
                <span onClick={() => handleView(item)}><ViewTableSvg /></span>,
              ]}
              style={{ width: 222 }}
            >
              <Card.Meta
                title={<span className="w-100">
                  <Tooltip title={checktextByLang(item.localize)}>
                    <div className="text-long-cus">{checktextByLang(item.localize)}</div>
                    </Tooltip>
                    </span>
                  }
                description={
                  <>
                    <Tooltip title={checktextByLangSub(item.localize)}>
                    <p style={{ fontSize: "12px" }}>{checktextByLangSub(item.localize).length > 30 ? checktextByLangSub(item.localize).slice(0, 30) + "..." : checktextByLangSub(item.localize)}</p>
                    </Tooltip>

                    <Col md={24} className="mt-2 me-3">
                      <TagTextComponent textData={item.category ? [item.category] : []} />
                    </Col>
                  </>
                }
              />
            </Card>
          </Col>
        ))
        }
      </Row>
    </>
  );
};

export default NewPostCard;
