import {
    InputNumber,
    Segmented,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import JSONInput from 'react-json-editor-ajrm';
import { AccordionSvg, AlignCenterSvg, AlignJustifySvg, AlignLetfSvg, AlignRigthSvg, BoldMobileSvg, ButtonSvg, CoverSvg, H1Svg, H2Svg, H3Svg, H4Svg, H5Svg, ImageSvg, ItalicSvg, LinkSvg, StrikethroughMobileSvg, UnderlineSvg, UnorderedListSvg } from '../../assets/images/DragSvg';
import SelectComponent from '../select/SelectComponent';
import { useTranslation } from 'react-i18next';
import { isEmptyObject } from '../../utils/string';
import UploadComponent from '../upload/UploadComponent';


const RightDragTextComponent = ({ compose, edit, template, itemData, setItemData, types }) => {
    const { i18n, t } = useTranslation();

    const [styleTypeTags, setStyleTypeTag] = useState([
        {
            label: t("Body"),
            tag: "content",
            value: "content",
        },
        {
            label: t("Bullet"),
            tag: "bullet",
            value: "bullet",
        },
        {
            label: t("Button"),
            tag: "button",
            value: "button",
        },
        {
            label: t("H1"),
            tag: "h1",
            value: "h1",
        },
        {
            label: t("H2"),
            tag: "h2",
            value: "h2",
        },
        {
            label: t("H3"),
            tag: "h3",
            value: "h3",
        },
        {
            label: t("H4"),
            tag: "h4",
            value: "h4",
        },
        {
            label: t("H5"),
            tag: "h5",
            value: "h5",
        },
        {
            label: t("Link Button"),
            tag: "link",
            value: "link",
        },
        {
            label: t("Image"),
            tag: "image",
            value: "image",
        },
        {
            label: t("Accordion"),
            tag: "accordion",
            value: "accordion",
        },
    ])
    let styleTypeTag = styleTypeTags.filter(it => {
        if(it.tag === "accordion" && itemData.tag === "accordion") {
            return it
        }
        return it.tag !== "accordion" 
    })

    const _RenderPreview = (items) => {
        if (!items) {
            return ''
        }
        let arr = []
        let i = 0
        let item = items ? items : {}
        let result = []
        let tag = item && item.tag ? item.tag : ''
        switch (tag) {
            case `content`:
                result.push(
                    <>
                        {/* <div className='col-12'>
                            <div className='w-100 d-flex justify-content-between'>
                                <span>Text</span>
                            </div>
                        </div> */}
                        <div className='col-12 mt-2'>
                            <div className='w-100'>
                                <div className="w-100 content-cus-body">
                                    <FloatingLabel
                                        label={t(`Text`)} className="mb-1-">
                                        <Form.Control
                                            type="input"
                                            as="textarea"
                                            style={{ minHeight: '80px' }}
                                            // placeholder={`Please set Text`}
                                            value={itemData?.text || ""}
                                            onChange={(e) => {
                                                itemData.text = e.target.value
                                                setItemData({ ...itemData })
                                            }}
                                        />
                                    </FloatingLabel>
                                </div>
                            </div>
                        </div>
                    </>
                );
                break;
            case `bullet`:
                result.push(
                    <>
                        <div className='col-12 mt-2'>
                            <div className='w-100'>
                                <div className="w-100 content-cus-body">
                                    <FloatingLabel
                                        label={t(`Text`)} className="mb-1-">
                                        <Form.Control
                                            type="input"
                                            as="textarea"
                                            style={{ minHeight: '80px' }}
                                            // placeholder={`Please set Text`}
                                            value={itemData?.text || ""}
                                            onChange={(e) => {
                                                itemData.text = e.target.value
                                                setItemData({ ...itemData })
                                            }}
                                        />
                                    </FloatingLabel>
                                </div>
                            </div>
                        </div>
                    </>
                );
                break;
            case `button`:
                result.push(
                    <>
                        <div className='col-12 mt-2'>
                            <div className='w-100'>
                                <div className="w-100 content-cus-body">
                                    <FloatingLabel
                                        label={t(`Title`)} className="mb-1-">
                                        <Form.Control
                                            type="input"
                                            value={itemData?.text || ""}
                                            onChange={(e) => {
                                                itemData.text = e.target.value
                                                setItemData({ ...itemData })
                                            }}
                                        />
                                    </FloatingLabel>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='w-100 d-flex justify-content-between'>
                                <span>
                                    <div>{t("Navigation")} (JSON):</div>
                                    {t("Ask Developer for the value")}
                                </span>
                            </div>
                        </div>
                        <div className='col-12 mt-2'>
                            <div className='w-100'>
                                <div className="w-100 content-cus-body">
                                    <JSONInput
                                        placeholder={
                                            typeof itemData?.navigation !== 'object'
                                                ? types.find((t) => t.tag === 'button')?.default?.navigation
                                                : itemData?.navigation
                                        }
                                        theme={'light_mitsuketa_tribute'}
                                        width={'100%'}
                                        height="auto"
                                        style={{
                                            contentBox: { cursor: 'text' },
                                            body: { border: '1px lightgrey solid', borderRadius: '4px' },
                                        }}
                                        onBlur={(e) => {
                                            console.log(`skhsk-e.jsObject: `, e?.jsObject)
                                            if (!e.error && e?.jsObject) {
                                                itemData.navigation = e?.jsObject;
                                                setItemData({ ...itemData })
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                );
                break;
            case `h1`:
            case `h2`:
            case `h3`:
            case `h4`:
            case `h5`:
                result.push(
                    <>
                        <div className='col-12 mt-2'>
                            <div className='w-100'>
                                <div className="w-100 content-cus-body">
                                    <FloatingLabel
                                        label={t(`Text`)} className="mb-1-">
                                        <Form.Control
                                            type="input"
                                            as="textarea"
                                            style={{ minHeight: '80px' }}
                                            // placeholder={`Please set Text`}
                                            value={itemData?.text || ""}
                                            onChange={(e) => {
                                                itemData.text = e.target.value
                                                setItemData({ ...itemData })
                                            }}
                                        />
                                    </FloatingLabel>
                                </div>
                            </div>
                        </div>
                    </>
                );
                break;
            case `link`:
                result.push(
                    <>
                        <div className='col-12 mt-2'>
                            <div className='w-100'>
                                <div className="w-100 content-cus-body">
                                    <FloatingLabel
                                        label={t(`Title`)} className="mb-2">
                                        <Form.Control
                                            type="input"
                                            // placeholder={`Please set Title`}
                                            value={itemData?.text || ""}
                                            onChange={(e) => {
                                                itemData.text = e.target.value
                                                setItemData({ ...itemData })
                                            }}
                                        />
                                    </FloatingLabel>
                                    <FloatingLabel
                                        label={t(`Link`)} className="mb-1-">
                                        <Form.Control
                                            type="input"
                                            // placeholder={`Please set link`}
                                            value={itemData?.link || ""}
                                            onChange={(e) => {
                                                itemData.link = e.target.value
                                                setItemData({ ...itemData })
                                            }}
                                        />
                                    </FloatingLabel>
                                </div>
                            </div>
                        </div>
                    </>
                );
                break;
            case `image`:
                result.push(
                    <>
                        <div className='col-12 mt-2'>
                            <div className='w-100'>
                                <div className="w-100 content-cus-body">
                                    <UploadComponent
                                        fileList={itemData.url ? [{
                                            "uid": "1",
                                            "name": "",
                                            "status": "done",
                                            "url": itemData.url
                                        }] : []}
                                        setFileList={(values) => {
                                            itemData.url = values.url
                                            setItemData({ ...itemData })
                                        }}
                                        uploadavatarTitle={t('Upload')}
                                        hideAvatarTitle={true}
                                        className={`d-flex justify-content-center`}
                                    // isError={isSubmit && !picUrl && `error`}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                );
                break;
            case `accordion`:
                result.push(
                    <>
                        <div className='col-12 mt-2'>
                            <div className='w-100'>
                                <div className="w-100 content-cus-body">
                                    <FloatingLabel
                                        label={`Header`} className="mb-1-">
                                        <Form.Control
                                            type="input"
                                            as="textarea"
                                            style={{ minHeight: '80px' }}
                                            // placeholder={`Please set Text`}
                                            value={itemData?.text || ""}
                                            onChange={(e) => {
                                                itemData.text = e.target.value
                                                setItemData({ ...itemData })
                                            }}
                                        />
                                    </FloatingLabel>
                                </div>
                            </div>
                        </div>
                    </>
                );
                break;

            default:
                break;
        }
        return result
    }


    return <div className="w-100 pe-4 ps-4 pt-4 pb-4 h-100" style={{ background: "#ffffff", borderRadius: "8px" }}>
        <div className="w-100">
            <div className='w-100 d-flex justify-content-between'>
                <span>{t("Text Style")}</span>
            </div>
            <div className='w-100'>
                <div className='row'>
                    <div className='col-6'>
                        <div className='w-100'>
                            <div className='row'>
                                <div className='col-3'>
                                    <div className="cursor-grab d-flex flex-wrap align-content-center justify-content-center mt-2 flex-column text-center">
                                        <div className={`${itemData && itemData.textDecoration === "underline" ? "active-under" : ""}`} style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                itemData.textDecoration = itemData && itemData.textDecoration === "underline" ? "none" : "underline"
                                                setItemData({ ...itemData })
                                            }}
                                        ><UnderlineSvg /></div>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className="cursor-grab d-flex flex-wrap align-content-center justify-content-center mt-2 flex-column text-center">
                                        <div className={`${itemData && itemData.textDecoration === "line-through" ? "active-under" : ""}`} style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                itemData.textDecoration = itemData && itemData.textDecoration === "line-through" ? "none" : "line-through"
                                                setItemData({ ...itemData })
                                            }}
                                        ><StrikethroughMobileSvg /></div>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className="cursor-grab d-flex flex-wrap align-content-center justify-content-center mt-2 flex-column text-center">
                                        <div className={`${itemData && itemData.italicType === "italic" ? "active-under" : ""}`} style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                itemData.italicType = itemData && itemData.italicType === "italic" ? "normal" : "italic"
                                                setItemData({ ...itemData })
                                            }}
                                        ><ItalicSvg /></div>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className="cursor-grab d-flex flex-wrap align-content-center justify-content-center mt-2 flex-column text-center">
                                        <div className={`${itemData && itemData.fontWeight === "bold" ? "active-under" : ""}`} style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                itemData.fontWeight = itemData && itemData.fontWeight === "bold" ? "" : "bold"
                                                setItemData({ ...itemData })
                                            }}
                                        ><BoldMobileSvg /></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-6'>
                        <SelectComponent
                            options={styleTypeTag}
                            value={itemData?.tag || null}
                            allowClear={false}
                            setValue={(value) => {
                                itemData.tag = value
                                setItemData({ ...itemData })
                            }}
                            // placeholder={`Select tag`}
                            size={"middle"}
                        />
                    </div>
                    <div className='col-12'>
                        <div className="w-100"><hr /></div>
                    </div>
                    <div className='col-12'>
                        <div className='w-100 d-flex justify-content-between'>
                            <span>{t("Alignment")}</span>
                        </div>
                    </div>
                    <div className='col-12 mt-2 text-center'>
                        <Segmented
                            value={itemData?.textAlign}
                            onChange={(value) => {
                                itemData.textAlign = value
                                setItemData({ ...itemData })
                            }}
                            style={{
                                border: "1px solid #ECECEF",
                                // marginBottom: 8,
                                backgroundColor: '#ffffff', // Custom background color
                                // padding: '10px',
                                // borderRadius: '8px',
                                // borderColor: '#1890ff', // Custom border color
                                // color: '#1890ff', // Custom font color
                            }}
                            className='segmented-cus'
                            options={[
                                {
                                    label: (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span role="img" aria-label="start" style={{ marginRight: 5 }}><AlignLetfSvg /></span>
                                        </div>
                                    ),
                                    value: 'start',
                                },
                                {
                                    label: (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span role="img" aria-label="center" style={{ marginRight: 5 }}><AlignCenterSvg /></span>
                                        </div>
                                    ),
                                    value: 'center',
                                },
                                {
                                    label: (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span role="img" aria-label="end" style={{ marginRight: 5 }}><AlignRigthSvg /></span>
                                        </div>
                                    ),
                                    value: 'end',
                                },
                                {
                                    label: (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span role="img" aria-label="justify" style={{ marginRight: 5 }}><AlignJustifySvg /></span>
                                        </div>
                                    ),
                                    value: 'justify',
                                },
                            ]}
                        />
                    </div>
                    <div className='col-12'>
                        <div className="w-100"><hr /></div>
                    </div>
                    <div className='col-12'>
                        <div className='w-100 d-flex justify-content-between'>
                            <span>{t("Container fill")}</span>
                        </div>
                    </div>
                    <div className='col-6 mt-2'>
                        <div>{t("Up")}</div>
                        <div>
                            <InputNumber type='number'
                                addonAfter={<span style={{ cursor: "pointer" }} onClick={(value) => setItemData({ ...itemData, marginTop: itemData.marginTop !== undefined ? itemData.marginTop - 0 + 1 : 1 })}>{"+"}</span>}
                                addonBefore={<span style={{ cursor: "pointer" }} onClick={() => setItemData({ ...itemData, marginTop: itemData.marginTop !== undefined ? itemData.marginTop - 0 - 1 : -1 })}>{"-"}</span>}
                                value={itemData?.marginTop ? itemData.marginTop : 0}
                                onChange={(value) => {
                                    itemData.marginTop = value
                                    setItemData({ ...itemData })
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-6 mt-2'>
                        <div>{t("Down")}</div>
                        <div>
                            <InputNumber type='number'
                                addonAfter={<span style={{ cursor: "pointer" }} onClick={(value) => setItemData({ ...itemData, marginBottom: itemData.marginBottom !== undefined ? itemData.marginBottom - 0 + 1 : 1 })}>{"+"}</span>}
                                addonBefore={<span style={{ cursor: "pointer" }} onClick={() => setItemData({ ...itemData, marginBottom: itemData.marginBottom !== undefined ? itemData.marginBottom - 0 - 1 : -1 })}>{"-"}</span>}
                                value={itemData?.marginBottom}
                                onChange={(value) => {
                                    itemData.marginBottom = value
                                    setItemData({ ...itemData })
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-6 mt-2'>
                        <div>{t("Left")}</div>
                        <div>
                            <InputNumber type='number'
                                addonAfter={<span style={{ cursor: "pointer" }} onClick={(value) => setItemData({ ...itemData, marginLeft: itemData.marginLeft !== undefined ? itemData.marginLeft - 0 + 1 : 1 })}>{"+"}</span>}
                                addonBefore={<span style={{ cursor: "pointer" }} onClick={() => setItemData({ ...itemData, marginLeft: itemData.marginLeft !== undefined ? itemData.marginLeft - 0 - 1 : -1 })}>{"-"}</span>}
                                value={itemData?.marginLeft}
                                onChange={(value) => {
                                    itemData.marginLeft = value
                                    setItemData({ ...itemData })
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-6 mt-2'>
                        <div>{t("Right")}</div>
                        <div>
                            <InputNumber type='number'
                                addonAfter={<span style={{ cursor: "pointer" }} onClick={(value) => setItemData({ ...itemData, marginRight: itemData.marginRight !== undefined ? itemData.marginRight - 0 + 1 : 1 })}>{"+"}</span>}
                                addonBefore={<span style={{ cursor: "pointer" }} onClick={() => setItemData({ ...itemData, marginRight: itemData.marginRight !== undefined ? itemData.marginRight - 0 - 1 : -1 })}>{"-"}</span>}
                                value={itemData?.marginRight}
                                onChange={(value) => {
                                    itemData.marginRight = value
                                    setItemData({ ...itemData })
                                }}
                            />
                        </div>
                    </div>

                    <div className='w-100 h-w '>
                        <div className='row'>
                            <div className='col-6 mt-2'>
                                <div>{t("Height")}</div>
                                <div>
                                    <InputNumber type='number'
                                        addonAfter={<span style={{ cursor: "pointer" }} onClick={(value) => setItemData({ ...itemData, height: itemData.height !== undefined ? itemData.height - 0 + 1 : 1 })}>{"+"}</span>}
                                        addonBefore={<span style={{ cursor: "pointer" }} onClick={() => setItemData({ ...itemData, height: itemData.height !== undefined ? itemData.height - 0 - 1 : -1 })}>{"-"}</span>}
                                        value={itemData?.height ? itemData.height : 0}
                                        onChange={(value) => {
                                            itemData.height = value
                                            setItemData({ ...itemData })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-6 mt-2'>
                                <div>{t("Width")}</div>
                                <div>
                                    <InputNumber type='number'
                                        addonAfter={<span style={{ cursor: "pointer" }} onClick={(value) => setItemData({ ...itemData, width: itemData.width !== undefined ? itemData.width - 0 + 1 : 1 })}>{"+"}</span>}
                                        addonBefore={<span style={{ cursor: "pointer" }} onClick={() => setItemData({ ...itemData, width: itemData.width !== undefined ? itemData.width - 0 - 1 : -1 })}>{"-"}</span>}
                                        value={itemData?.width}
                                        onChange={(value) => {
                                            itemData.width = value
                                            setItemData({ ...itemData })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {!isEmptyObject(itemData) ? <div className='col-12'>
                        <div className="w-100"><hr /></div>
                    </div> : ""}

                    <>
                        {
                            !isEmptyObject(itemData) && _RenderPreview(itemData)
                        }
                    </>

                </div>

            </div>
        </div>
    </div>
}
export default RightDragTextComponent;