import { CopyMobileSvg, DeleteMobileSvg } from "../../assets/images/DragSvg"

export const CopyDeletComponent = ({handelCopy, handelDelete}) => {

    return <div className="w-100 d-flex justify-content-center" style={{ position: 'relative' }}>
        <div className="copy-delete">

            <div className="me-3">
                <span onClick={handelCopy} style={{cursor: "pointer"}}><CopyMobileSvg /></span>
            </div>
            <div className="">
                <span onClick={handelDelete} style={{cursor: "pointer"}}><DeleteMobileSvg /></span>
            </div>

        </div>
    </div>
}
