import { useState } from "react";
import { BackMobleSvg } from "../../assets/images/DragSvg";
import TagTextComponent from "../tag/TagTextComponent";
import DragInput from "./DragInput";
import DragInputTextViewEditView from "./DragInputTextViewEditView";
import { useTranslation } from "react-i18next";
import { ImageNo } from "../image/ImageComponent";
import { formatDate } from "../../utils/dateFormatUtils";
import { Image } from "antd";

const SubmitIframeTextDragView = ({ title, number, formLink, isDevice, data, contentDroppableAreaRender }) => {

    const { i18n, t } = useTranslation();
    let condeLang = "cn"//i18n.language ? i18n.language : "cn"
    let dataLang = data && data.localize && data.localize[condeLang] ? data.localize[condeLang] : null
    
    const checktextByLang = (dataCode) => {
      let text = dataCode && dataCode[condeLang] && dataCode[condeLang].title ? dataCode[condeLang].title : ''
      const languages = dataCode ?  Object.keys(dataCode) : []
      if (!text) {
        for (let lang of languages) {
          if (dataCode && dataCode[lang] && dataCode[lang].title) {
            text = dataCode[lang].title;
            break;
          }
        }
      }
      return text
    }
    const checktextByLangSub = (dataCode) => {
      let text = dataCode && dataCode[condeLang] && dataCode[condeLang].subtitle ? dataCode[condeLang].subtitle : ''
      const languages = dataCode ?  Object.keys(dataCode) : []
      if (!text) {
        for (let lang of languages) {
          if (dataCode && dataCode[lang] && dataCode[lang].subtitle) {
            text = dataCode[lang].subtitle;
            break;
          }
        }
      }
      return text
    }
    const [showfull, setshowFull] = useState(false)
    const [previewOpen, setPreviewOpen] = useState(false);
    const handlePreviewImage = async (url) => {
        setPreviewOpen(url);
    };

    return <>
        {previewOpen && (
            <Image
                wrapperStyle={{
                    display: 'none',
                }}
                preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) => setPreviewOpen(visible),
                    afterOpenChange: (visible) =>
                        !visible && setPreviewOpen(false),
                }}
                src={previewOpen}
            />
        )}
        {/* <iframe className="w-100 h-100" style={{ backgroundColor: 'white' }} src={`${API_ENDPOINT_CSL_PLASMA}/ShareFormPage?isView=show&title=${title}&number=${number}&isDevice=${isDevice}`} title={`${title}`}></iframe> */}
        <div className="w-100 d-flex- flex-wrap- h-100 content-dynamic-main">
            <div className="w-100 d-flex pt-2 pb-2">
                <div>
                    <BackMobleSvg />
                </div>
                <div className="title-content">{t('Information Details')}</div>
            </div>

            <div className="w-100 content-dynamic-scrolls">
                <div className="w-100">
                    <img onClick={(() => handlePreviewImage(data && data.coverimage && data.coverimage.url ? data.coverimage.url : ImageNo))}  src={data && data.coverimage && data.coverimage.url ? data.coverimage.url : ImageNo}
                        style={{cursor: "pointer"}}
                        className="image-mobile"
                    />
                </div>
                <div className="w-100 pt-1 pb-1" style={{cursor: "pointer"}}>
                    <div className={`w-100 tile-new-mobile pt-1 pb-1 ${!showfull ? 'text-long-cus-' : ''}`} onClick={() => setshowFull(!showfull)}>
                        {/* {dataLang && dataLang.title || "NA"} */}
                        {!showfull ? dataLang && dataLang.title && dataLang.title.length > 20 ? `${dataLang.title.slice(0, 20)}......` :  dataLang && dataLang.title || "NA" : dataLang && dataLang.title || "NA"}
                    </div>
                    <div className={`w-100 subtile-new-mobile pb-1 ${!showfull ? 'text-long-cus-' : ''}`} onClick={() => setshowFull(!showfull)}>
                        {!showfull ? dataLang && dataLang.subtitle && dataLang.subtitle.length > 25 ? `${dataLang.subtitle.slice(0, 25)}......` :  dataLang && dataLang.subtitle || "NA" : dataLang && dataLang.subtitle || "NA"}
                        {/* {dataLang && dataLang.subtitle || "NA"} */}
                    </div>
                    <div className="w-100 d-flex justify-content-between">
                        <div>
                            <TagTextComponent classNameTag={`font-size-11`} textData={ data && data.category ? [data.category] : []} />
                        </div>
                        <div className="font-size-11" style={{ opacity: "0.4" }}>
                            {/* 2025-11-23 */}
                            {data && data.createdAt ? formatDate(data.createdAt) : ""}
                        </div>
                    </div>
                </div>

                <div className="w-100">
                    <div className="w-100 dynamic-cus">
                        <DragInputTextViewEditView contentDroppableAreaRender={contentDroppableAreaRender} data={data} dataLang={dataLang} />
                    </div>
                </div>
            </div>

        </div>
    </>
}
export default SubmitIframeTextDragView;