import { Avatar, Col, Popover, Row } from "antd";
import React from "react";
import { IoPower } from "react-icons/io5";
import CustomMenu from "./CustomMenu";
import { useNavigate } from "react-router-dom";
import SwitchLanguage from "../switchLanguages/SwitchLanguage";
import { useTranslation } from "react-i18next";
import LogoESI from '../../assets/images/profile.png'
import {
    DownOutlined
} from '@ant-design/icons';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { userLogout } from "../../stores/authenticated-user.store";

const NavigationTopper = ({pageTitle}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useSelector((state) => state.authenticatedUser);
    const dispatch = useDispatch();

    function getItem(label, key, icon, children, type) {
        return {
            key,
            icon,
            children,
            label,
            type,
        };
    }

    const items = [
        {
            type: 'divider',
        },
        getItem(
            <a
                style={{
                    color: 'red',
                }}
                onClick={async () => {
                    navigate('/login');
                    // await bioApi.post('/auth/logout');
                    dispatch(userLogout(null));
                }}
                rel="noopener noreferrer"
            >
                {t('logout')}
            </a>,
            'logout',
            <IoPower
                style={{
                    color: 'red',
                }}
            />
        ),
    ];

    const content = (
        <div
            style={{
                padding: 8,
                maxWidth: '260px',
            }}
        >
            <div>
                <Row gutter={16}>
                    <Col className="gutter-row" span={6}>
                        <img 
                        style={{borderRadius: "50%"}}
                        alt='ESI-Logo' role='button' src={user && user.picUrl ? user.picUrl : LogoESI} className={`esi-logo54`} />
                        {/* <Avatar style={{ backgroundColor: "rgb(0, 162, 174)", verticalAlign: 'middle' }} size="large">
                            CH
                        </Avatar> */}
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <div >{user && user.name || ""}</div>
                        <div
                            style={{
                                color: 'lightGrey',
                            }}
                        >
                            {user && user.staffId || ""}
                        </div>
                        <div className="pb-3">{user && user.email || ""}</div>
                    </Col>
                </Row>
            </div>
            <CustomMenu items={items} />
        </div>
    );
    return (
        <div className="w-100 bg-white- ps-4 pe-4"
            style={{
                // padding: 15,
                // boxShadow: "rgb(221, 221, 221) 0px 3px 10px"
            }}
        >
            <div className="d-flex justify-content-between align-items-center ps-4- m-0">
                <h4 className="m-0 text-wrap-nowrap navHeader20500">{pageTitle || ""}</h4>
                <div className="d-flex align-items-center">
                    <SwitchLanguage />
                    <div className="align-items-center d-flex">
                        <Popover content={content} trigger="click">
                            <div
                                style={{
                                    cursor: 'pointer',
                                }}
                                className="userGroup"
                            >
                                <img alt='ESI-Logo' role='button' src={user && user.picUrl ? user.picUrl : LogoESI} className={`esi-logo54`} style={{borderRadius: "50%"}} />
                            </div>
                        </Popover>
                        <Popover content={content} trigger="click">
                            <div
                                style={{
                                    cursor: 'pointer',
                                }}
                                className="userGroup ps-2"
                            >
                            <div style={{ lineHeight: "normal" }}>
                                <div>{user && user.name || ""}</div>
                                <div>{user && user.role || ""}</div>
                            </div>
                            </div>
                        </Popover>
                        <Popover content={content} trigger="click">
                            <div
                                style={{
                                    cursor: 'pointer',
                                }}
                                className="userGroup ps-2"
                            >
                                <DownOutlined />
                            </div>
                        </Popover>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavigationTopper