import React, { useRef, useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Button, message, Upload, Image, Spin } from 'antd';
import MessageConfirmModal from '../messageModal/MessageConfirmModal';
import UploadColorSvg from '../../assets/images/uploadColorSvg';
import { useTranslation } from 'react-i18next';
import EmptyImageSvg from '../../assets/images/EmptyImageSvg';
import ChangeCoverSvg from '../../assets/images/ChangeCoverSvg';
import axios from 'axios'; // Import axios
import { ADMIN_ENDPOINT } from '../../config/api'; // Your API endpoint
import { postDataRequest } from '../../api/serviceMethods';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { UPLOAD_PRIVATE_URL } from '../../api/URLs';
import { RemoveSvg } from '../../assets/images/RemoveSvg';
import { formatFileSize, getFileType } from '../../utils/string';
import { Document } from 'react-pdf';

const { Dragger } = Upload;

const DragUploadPrivateComponentPdf = ({
    size = 1,
    fileList,
    setFileList,
    previewUrl,
    isError = false,
    isRequire = false,
    uploadavatarTitle = "",
    changeAvatarTitle = "",
    allowType = [ "application/pdf","application/epub+zip", "application/x-mobipocket-ebook", "application/vnd.amazon.mobi8-ebook"],
    titleallowFile="",
    privateFile = false,
    maxSize
}) => {
    const messageModalRef = useRef("");
    const { i18n, t } = useTranslation();

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const uploadRef = useRef(null);
    const [loading, setLoading] = useState(false);

    // Convert image file to base64
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    // Before upload validation
    const beforeUpload = (file) => {
        if (!allowType.includes(file.type)) {
            message.error('You cannot upload this file type!');
            return false;
        }
        const isLt2M = file.size / 1024 / 1024 <  (maxSize ?? 10);
        if (!isLt2M) {
            message.error('Image must be smaller than 10MB!');
            return false;
        }
        return true;
    };

    
    // Handle preview for image files
    const handlePreviewImage = async (file) => {
        setPreviewImage(previewUrl ?? file.url);
        setPreviewOpen(true);
    };

    // Handle preview for image files
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await new Promise((resolve) => {
                getBase64(file.originFileObj, resolve);
            });
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const getFilePage = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            if (file) {
                reader.readAsBinaryString(file);
                reader.onloadend = () => {
                    const count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
                    resolve(count);
                }
                reader.onerror = (error) => {
                    reject(error); 
                };
            } else {
                resolve(0); 
            }
        });
    }

    // Custom upload logic using axios and FormData
    const handleCustomUpload = async (options) => {
        const { file, onSuccess, onError } = options;
        const formData = new FormData();
        formData.append('file', file);

        try {
            setLoading(true);

            const [count, response] = await Promise.all([
                getFilePage(file),
                uploadPrivateFile(formData),
            ]);

            if (response.fileUrl) {
                // Check the response and use the fileUrl from the response
                onSuccess(response);

                if (size === 1) {
                    setFileList([
                        {
                            page: count,
                            uid: file.uid,
                            name: file.name,
                            status: 'done',
                            url: response.fileUrl,
                            size: file.size
                        }
                    ]);
                } else {
                    setFileList((prev) => [
                        ...prev,
                        {
                            page: count,
                            uid: file.uid,
                            name: file.name,
                            status: 'done',
                            url: response.fileUrl,
                            size: file.size
                        },
                    ]);
                }
            
                setLoading(false);
            } else {
                setLoading(false);
                throw new Error('Invalid response structure');
            }
        } catch (error) {
            setLoading(false);
            onError(error);
            message.error('Upload failed. Please try again.');
        }
    };

    const uploadPrivateFile = async (formData) => {
        return await postDataRequest(UPLOAD_PRIVATE_URL, formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // Ensure the correct header for file upload
            },
        });
    }

    // Handle file removal
    const handleRemove = (file) => {
        const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
        setFileList(updatedFileList);
    };

    // Handle change for fileList
    const handleChange = (info) => {
        if (info.file.status === 'done') {
            // message.success(`${info.file.name} file uploaded successfully.`);
        } else if (info.file.status === 'error') {
            // message.error(`${info.file.name} file upload failed.`);
        }
    };

    // Change cover handler
    const handleChangeCover = () => {
        uploadRef.current.click();
    };

    const props = {
        beforeUpload,
        customRequest: handleCustomUpload,
        multiple: false,
        onChange: handleChange,
        onDrop: (e) => {
            console.log("Dropped files", e.dataTransfer.files);
        },
        onPreview: handlePreview,
        className: 'newUpload',
        showUploadList: false,
        onRemove: handleRemove,
        fileList: fileList
    };

    return (
        <>
            <div className={`w-100 ${fileList.length > 0 && size === 1 ? 'd-none' : ''} `}>
                <Dragger  {...props}>
                    <Spin spinning={loading}>
                        <>
                            {
                                size > 1 && fileList && fileList.length > 0 ? fileList.map((it, i) => (
                                    <div className='d-flex mt-1 mt-1 justify-content-between align-items-center ps-4 pe-4 pt-4 pb-4' style={{ background: "rgb(0 0 0 / 2%)", borderRadius: "8px" }}>
                                        <div className='d-flex align-items-center'>
                                            <div className='pe-3'>
                                                <img 
                                                // onClick={(() => handlePreviewImage(it))} 
                                                style={{ width: "110px", height: "74px", borderRadius: "6px", objectFit: "cover" }} src={`${it.url}`} />
                                            </div>
                                            <div className='text-image'>
                                                <div style={{ textAlign: "left" }}>{it.name || 'NA'}</div>
                                                <div style={{ textAlign: "left" }}>
                                                    <span className='text-uppercase text-wrap-nowrap'>{it.name && getFileType(it.name)}</span>
                                                    <span className='ps-2' style={{ paddingBottom: "6px" }}>.</span>
                                                    <span className='ps-2'>{it.size ? formatFileSize(it.size) : `0 MB`}</span>

                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <span className='remove-icon' onClick={() => {
                                                fileList.splice(i, 1);
                                                setFileList([...fileList])
                                            }} style={{ cursor: "pointer" }}><RemoveSvg /></span>
                                        </div>
                                    </div>
                                )) : ''
                            }
                            {size > 1 && fileList && fileList.length === 0 || size === 1 ? <>
                            <Button
                                style={{
                                    height: "48px",
                                    width: "48px",
                                    backgroundColor: "#F5F7F9",
                                    border: "none"
                                }}
                                ref={uploadRef}
                            >
                                <UploadColorSvg />
                            </Button>
                            <div className='mt-2'>
                                <p className="ant-upload-text mt-2">
                                    {t("Drag files here or")}
                                    <a href="#!" style={{ marginLeft: "5px", textDecoration: "underline" }}>{t("Select File")}</a>
                                </p>
                                <p className="ant-upload-hint">
                                    {titleallowFile ? titleallowFile : t(`Supports pdf/epub/mobi/azw3 and other formats, the maximum file size is 50MB`)}
                                </p>
                            </div>
                            </> : ''}
                        </>
                    </Spin>
                </Dragger>
            </div>

            {fileList && fileList.length > 0 && size === 1 ? <div className='ant-upload-wrapper-cus w-100'>
                <div className='ant-upload ant-upload-drag align-content-center pe-3 ps-3 pt-4 pb-4'>
                    {
                        fileList && fileList.length > 0 ? fileList.map((it, i) => (
                            <div className='d-flex mt-1 mt-1 justify-content-between align-items-center ps-4 pe-4 pt-4 pb-4' style={{ background: "rgb(0 0 0 / 2%)", borderRadius: "8px" }}>
                                <div className='d-flex align-items-center'>
                                    <div className='pe-3'>
                                        <img onClick={(() => handlePreviewImage(it))} style={{ width: "110px", height: "74px", borderRadius: "6px", objectFit: "cover" }} src={`${previewUrl}`} />
                                    </div>
                                    <div className='text-image'>
                                        <div style={{ textAlign: "left" }}>{it.name || 'NA'}</div>
                                        <div style={{ textAlign: "left" }}>
                                            <span className='text-uppercase text-wrap-nowrap'>{it.name && getFileType(it.name)}</span>
                                            <span className='ps-2' style={{ paddingBottom: "6px" }}>.</span>
                                            <span className='ps-2'>{it.size ? formatFileSize(it.size) : `0 MB`}</span>
                                            <span className='ps-2' style={{ paddingBottom: "6px" }}>.</span>
                                            {it.page && <span className='ps-2'>{it.page + ` ` + t("pageSize Pdf")}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <span onClick={() => {
                                        fileList.splice(i, 1);
                                        setFileList([...fileList])
                                    }} style={{ cursor: "pointer" }}><RemoveSvg /></span>
                                </div>
                            </div>
                        )) : ''
                    }
                </div>
            </div> : ''}

            {fileList.length >= 1 && (
                <div className="mt-3 d-none text-center d-flex align-items-center">
                    <ChangeCoverSvg className="me-3" />
                    <a
                        href="#!"
                        onClick={handleChangeCover}
                        style={{ marginLeft: '10px' }}
                    >
                        <span className="span-danger">*</span> {changeAvatarTitle ? changeAvatarTitle : t('Change avatar')}
                    </a>
                </div>
            )}
            {previewImage && (
                <Image
                    wrapperStyle={{
                        display: 'none',
                    }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) =>
                            !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
            <MessageConfirmModal textCentered ref={messageModalRef} />
        </>
    );
};

export default DragUploadPrivateComponentPdf;
