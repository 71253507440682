import {
    Tree,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

const LeftDragTextComponent = ({ compose, edit, template, types, layout, activeIndex, setActiveIndex, lang }) => {
    const { t } = useTranslation();

    const typeDraggingStyle = (isDragging, draggableStyle) => ({
        ...draggableStyle,
        background: isDragging ? 'lightgreen' : 'white',
        transform: isDragging ? draggableStyle?.transform : 'translate(0px, 0px)',
    });

    const checkIsActive = (key) => {
        if (!key) { return '' }
        const valueAfterAt = key.split("@")[1];
        return `${lang}-content-draggable@${valueAfterAt}`
    }

    return <div className="w-100">
        <div className="w-100"
            style={{
                minHeight: "10rem",
                maxHeight: "14rem",
                overflow: "auto"
            }}
        >
            {/* <Affix offsetTop={65}> */}
            <div style={{ cursor: 'pointer' }} onClick={() => window.scrollTo(0, 0)}>
                {t("Layout")}
            </div>
            <Tree
                showLine={{ showLeafIcon: false }}
                showIcon={true}
                showLeafIcon={false}
                className='cus-svg'
                selectedKeys={[checkIsActive(activeIndex)]}
                treeData={(() => {
                    const generateTree = (elements, indexList) => {
                        return elements.map((el, i) => ({
                            title: t(types.find((type) => type.tag === el.tag).text),
                            key: `${lang}-content-draggable@${indexList.join('-')}-${i}`,
                            icon: types.find((type) => type.tag === el.tag).icon,
                            children: el.accordion ? generateTree(el.accordion, [...indexList, i]) : [],
                        }));
                    };

                    return generateTree(layout, []);
                })()}
                onSelect={(selectedKeys) => {
                    if (selectedKeys.length > 0) {
                        const selectedKey = selectedKeys[0];
                        const el = document.querySelector(
                            `div[data-rbd-drag-handle-draggable-id="${selectedKey}"]`
                        );
                        if (el) {
                            el.scrollIntoView({ behavior: 'smooth' });
                        }
                        if (selectedKey) {
                            setActiveIndex(`${selectedKey}`)
                        }
                    }
                }}
            />
            {/* </Affix> */}
        </div>
        <div className="w-100"><hr /></div>
        <div className="w-100">
            <div className='w-100 d-flex justify-content-between'>
                <span>{t("Toolbar")}</span>
                <span style={{ opacity: "0.4", fontSize: "12px" }}>{t("Drag the tool")}</span>
            </div>
            <div className='w-100'>
                <Droppable key={'types'} droppableId={'types'} isDropDisabled={true}>
                    {(typesProvided, typesSnapshot) => {
                        return <>
                            <div
                                className="row"
                                ref={typesProvided.innerRef}
                                style={{ padding: 8 }}
                                {...typesProvided.droppableProps}
                            >
                                {types.map((type, index) => {
                                    return <Draggable key={type.tag} draggableId={type.tag} index={index}>
                                        {(provided, snapshot) => {
                                            return <div className='col-4'>
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        userSelect: "none",
                                                        ...typeDraggingStyle(snapshot.isDragging, provided.draggableProps.style),
                                                    }}
                                                >
                                                    <div className="cursor-grab d-flex flex-wrap align-content-center justify-content-center mt-2 flex-column text-center">
                                                        <div>{type.icon || ''}</div>
                                                        <div>{t(type.text)}</div>
                                                    </div>
                                                </div>
                                                {snapshot.isDragging && (
                                                    <div
                                                        style={{ userSelect: "none" }}
                                                    >
                                                        <div className="cursor-grab d-flex flex-wrap align-content-center justify-content-center mt-2 flex-column text-center">
                                                            <div>{type.icon || ''}</div>
                                                            <div>{t(type.text)}</div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        }}
                                    </Draggable>
                                })}
                            </div>
                        </>
                    }}
                </Droppable>
            </div>
        </div>
    </div>
}
export default LeftDragTextComponent;