export const LOGIN_URL = '/auth/login';
export const REQUEST_PASSWORD_RESET_URL = '/auth/requestToken';
export const VALIDATE_TOKEN_URL = '/auth/validateToken';
export const RESET_PASSWORD_URL = '/auth/resetPassword';

export const MEMBER_URL = '/members'
export const TREATMENT_FROM_ME_URL = '/auth/login';
export const TREATMENT_NEED_MY_REVIEW_URL = '/auth/login';
export const UPLOAD_IMAGE_URL = '/upload';
export const UPLOAD_IMAGE_URLNew = '/utils/upload-image';
export const NEWS_POSTS_URL = '/marketing/news-post/';
export const NEWS_GET_URL = '/news';
export const NEWS_CATEGORY_URL = '/news/category';
export const POST_EXPOSURE_FILTER_URL = '/marketing/news-post/exposure-filter';
export const AUTHORIZATION_ROLE_URL = '/authorizations/roles';
export const AUTHORIZATION_ROLE_CREATE_URL = '/authorizations/role';
export const AUTHORIZATION_RIGHTS_URL = '/authorizations/rights';
export const STAFF_LIST_URL = '/staffs/list';
export const STAFF_CREATE_URL = '/staffs/create';
export const STAFF_DELETE_URL = '/staffs/delete';
export const STAFF_DETAIL_URL = '/staffs';
export const AUTHORIZATION_RIGHT_URL = '/authorizations/right';
export const MAGAZINES_URL = '/magazines'
export const UPLOAD_PUBLIC_URL = '/files/upload'
export const UPLOAD_PRIVATE_URL = '/files/upload/excl'
export const GET_PRIVATE_URL = '/files'
export const DASHBOARD_PAYMENT_URL = "/payments/report"
export const PAYMENT_URL = "/payments"
export const MEMBER_REPORT_URL = "/members/report"
export const MEMBER_SUBSCRIPTION_URL ="/members/subscription"
export const TAGS_URL = "/tags"
