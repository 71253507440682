import React, { useEffect, useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import esiApi from '../../api/esiApi';
import { RESET_PASSWORD_URL, VALIDATE_TOKEN_URL } from '../../api/URLs';
import { useLocation, useNavigate } from 'react-router-dom';
import { message } from 'antd';

const PasswordResetPage = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get('token');
  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [loginData, setLoginData] = useState({
    login: '',
    password: '',
    passcode: '',
  });
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [passwordsMatchChange, setPasswordsMatchChange] = useState(false);

  const navigate = useNavigate();

  const fetchUserInformation = async (token) => {
    setLoading(true);
    const payload = {
      token: token,
    };
    await esiApi
      .post(VALIDATE_TOKEN_URL, payload)
      .then((res) => {
        setLoading(false);

        if (res) {
          setLoginData({
            login: res.staffId,
            password: '',
          });
        } else {
          message.error(res);
          navigate('/login');
        }
      })
      .catch((err) => {
        message.error(err || err.message);
        setLoading(false);
        navigate('/login');
      });
  };

  useEffect(() => {
    fetchUserInformation(token);

    //fetch user data from here using token
  }, []);

  const handleHide = () => {
    setIsShow(!isShow);
  };

  const validation = () => {
    if (!loginData.login || !passwordsMatch) {
      return true;
    }
    return !loginData.password;
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordsMatch(loginData.password === e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    if (validation()) {
      return false;
    }
    setLoading(true);
    const loginPayload = {
      login: loginData.login,
      password: loginData.password,
      token,
      passcode: loginData.passcode,
    };
    await esiApi
      .post(RESET_PASSWORD_URL, loginPayload)
      .then((res) => {
        setLoading(false);

        if (res) {
          message.success(res);
          navigate('/');
        } else {
          console.log(res);
          if (res.redirect) {
            message.error(res.message);
            navigate('/');
          }
          message.error(res);
        }
      })
      .catch((err) => {
        if (err.response.data.redirect) {
          message.error(err.response.data.message);
          navigate('/');
        } else {
          message.error(err.response.data || err.message);
        }

        setLoading(false);
      });
  };

  return (
    <div
      style={{
        borderTop: `6px solid #3f86c7`,
        backgroundColor: 'white',
        borderRadius: '4px',
        overflow: 'hidden',
        boxShadow: '0px 3px 10px #ddd',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '8rem',
        marginBottom: '8rem',
      }}
      className="auth_body"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          height: '32px',
          marginTop: '32px',
        }}
      >
        <p
          className='text-capitalize'
          style={{
            fontWeight: `700`,
            fontSize: '24px',
            marginBottom: '0',
            color: 'black',
          }}
        >
          RESET PASSWORD
        </p>
      </div>
      <form
        onSubmit={handleSubmit}
        style={{
          width: '100%',
          height: '100%',
          padding: '32px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '100%',
          }}
        >
          <>
            <label
              style={{
                marginBottom: '8px',
                fontWeight: '700',
                fontSize: '14px',
                marginTop: '18px',
              }}
            >
              Staff ID / Email
            </label>
            <input
              value={loginData.login}
              disabled={true}
              onChange={(e) => {
                setLoginData({
                  ...loginData,
                  login: e.target.value,
                });
              }}
              className={`form-control ${isSubmit && !loginData.login ? 'is-invalid' : ''}`}
              style={{
                height: '50px',
                marginBottom: '5px',
              }}
            />
            <div className="invalid-feedback">{isSubmit && !loginData.login ? 'The field is require!' : ''}</div>
          </>
          <div>
            <label
              style={{
                marginBottom: '8px',
                fontWeight: '700',
                fontSize: '14px',
                marginTop: '18px',
              }}
            >
              Password
            </label>
            <div style={{ position: 'relative' }}>
              <input
                type={isShow ? 'text' : 'password'}
                className={`form-control ${isSubmit && !loginData.password ? 'is-invalid' : ''}`}
                style={{
                  height: '50px',
                }}
                value={loginData.password}
                onChange={(e) => {
                  setLoginData({
                    ...loginData,
                    password: e.target.value,
                  });
                }}
              />
              <div className="invalid-feedback">{isSubmit && !loginData.password ? 'The field is require!' : ''}</div>
              {isSubmit && !loginData.password ? (
                ''
              ) : (
                <div
                  onClick={() => handleHide()}
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    margin: '15px 15px',
                    cursor: 'pointer',
                  }}
                >
                  {isShow ? <AiFillEye size={24} /> : <AiFillEyeInvisible size={24} />}
                </div>
              )}
            </div>
          </div>
          <div>
            <label
              style={{
                marginBottom: '8px',
                fontWeight: '700',
                fontSize: '14px',
                marginTop: '18px',
              }}
            >
              Confirm Password
            </label>
            <div style={{ position: 'relative' }}>
              <input
                type={isShow ? 'text' : 'password'}
                className={`form-control ${(isSubmit && !loginData.password) || !passwordsMatch && passwordsMatchChange ? 'is-invalid' : ''}`}
                style={{
                  height: '50px',
                }}
                value={confirmPassword}
                onChange={(e) => {
                  handleConfirmPasswordChange(e);
                  setPasswordsMatchChange(true)
                }}
              />
              <div className="invalid-feedback">
                {!passwordsMatch && passwordsMatchChange && 'Password not match'}
                {isSubmit && !loginData.password ? 'The field is require!' : ''}
              </div>
              {isSubmit && !loginData.password ? (
                ''
              ) : (
                <div
                  onClick={() => handleHide()}
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    margin: '15px 15px',
                    cursor: 'pointer',
                  }}
                ></div>
              )}
            </div>
          </div>
          <div>
            <label
              style={{
                marginBottom: '8px',
                fontWeight: '700',
                fontSize: '14px',
                marginTop: '18px',
              }}
            >
              Passcode
            </label>
            <div style={{ position: 'relative' }}>
              <input
                type="text"
                className={`form-control`}
                style={{
                  height: '50px',
                }}
                value={loginData.passcode}
                onChange={(e) => {
                  setLoginData({
                    ...loginData,
                    passcode: e.target.value,
                  });
                }}
              />
              <div className="invalid-feedback">{isSubmit && !loginData.passcode ? 'The field is require!' : ''}</div>
            </div>
          </div>
        </div>

        <button
          className="button_auth button_body"
          style={{ marginTop: '32px' }}
          onSubmit={() => handleSubmit()}
          disabled={loading}
        >
          <div
            style={{
              fontWeight: '800',
              fontSize: '16px',
              display: 'flex',
              alignItems: 'center',
              color: 'white',
            }}
          >
            {/* {loading && <Spin style={{ marginRight: 10 }} />} */}
            RESET PASSWORD
          </div>
        </button>
      </form>
    </div>
  );
};

export default PasswordResetPage;
