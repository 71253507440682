import { Button, Image } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const DragInputTextViewEditView = ({ isSubmit, dataObject, setDataObject, dataLang, data, contentDroppableAreaRender }) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const handlePreviewImage = async (url) => {
        setPreviewOpen(url);
    };

    const capitalizeFirstLetter = (str) => {
        return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
    };
    const styleCheck = (it) => {
        let styleNew = {
            textDecoration: it.textDecoration ? it.textDecoration : "",
            marginTop: it.marginTop ? it.marginTop : "0px",
            marginBottom: it.marginBottom ? it.marginBottom : "0px",
            marginLeft: it.marginLeft ? it.marginLeft : "0px",
            marginRight: it.marginRight ? it.marginRight : "0px",
            textAlign: it.textAlign ? it.textAlign : "0px",
            fontStyle: it.italicType ? it.italicType : "",
        }
        return styleNew
    }
    const _RenderPreview = (arr) => {
        if(!arr || arr && arr.length === 0) {
            return ''
        }
        let result = []
        for (let i = 0; i < arr.length; ++i) {
            switch (arr[i].tag) {
                case `content`:
                    result.push(
                        // <div style={{ marginBottom: arr[i].marginBottom || 0, ...fontStyle(arr[i], false) }}>{arr[i].text}</div>
                        <div className="w-100 content-cus-body"
                        style={styleCheck(arr[i])}
                        >
                            <div>{arr[i].text}</div>
                        </div>
                      );
                    break;
                case `bullet`:
                    const lis = [
                        <li 
                        // style={{ marginBottom: arr[i].marginBottom || 0, ...fontStyle(arr[i], false) }}
                        >
                          {capitalizeFirstLetter(arr[i].text)}
                        </li>,
                      ];
                      while (arr[i + 1]) {
                        if (arr[i + 1].tag === 'bullet') {
                          ++i;
                          lis.push(
                            <li 
                            // style={{ marginBottom: arr[i].marginBottom || 0, ...fontStyle(arr[i], false) }}
                            >
                              {capitalizeFirstLetter(arr[i].text)}
                            </li>
                          );
                        } else {
                          break;
                        }
                      }
                      result.push(<ul style={styleCheck(arr[i])} className="bullet-cus">{lis}</ul>);
                    break;
                case `button`:
                    result.push(
                        <div className="w-10 button-cus" style={styleCheck(arr[i])}>
                            {/* <Button style={styleCheck(arr[i])}>{capitalizeFirstLetter(arr[i].text)}</Button> */}
                            <Button
                                className='b-danger'
                                type="primary"
                                onClick={() => {
                                    
                                }}
                                style={{...styleCheck(arr[i]), 
                                    backgroundColor: "#E50A47",
                                    border: "1px solid #e72158",
                                }}
                                danger
                            >
                                {capitalizeFirstLetter(arr[i].text)}
                            </Button>
                        </div>
                    );
                    break;
                case `h1`:
                    result.push(
                        <div className="w-100 h1-div">
                            <h1 className="h1" style={styleCheck(arr[i])}>{capitalizeFirstLetter(arr[i].text)}</h1>
                        </div>
                      );
                    break;
                case `h2`:
                    result.push(
                        <div className="w-100 h1=2-div">
                            <h1 className="h2" style={styleCheck(arr[i])}>{capitalizeFirstLetter(arr[i].text)}</h1>
                        </div>
                      );
                    break;
                case `h3`:
                    result.push(
                        <div className="w-100 h3-div">
                            <h1 className="h3" style={styleCheck(arr[i])}>{capitalizeFirstLetter(arr[i].text)}</h1>
                        </div>
                      );
                    break;
                case `h4`:
                    result.push(
                        <div className="w-100 h4-div">
                            <h1 className="h4" style={styleCheck(arr[i])}>{capitalizeFirstLetter(arr[i].text)}</h1>
                        </div>
                      );
                    break;
                case `h5`:
                    result.push(
                        <div className="w-100 h5-div">
                            <h1 className="h5" style={styleCheck(arr[i])}>{capitalizeFirstLetter(arr[i].text)}</h1>
                        </div>
                      );
                    break;
                case `link`:
                    result.push(
                        <div className="w-100 link-div" style={styleCheck(arr[i])}>
                            <a className="link-mobile" style={styleCheck(arr[i])}>{capitalizeFirstLetter(arr[i].text)}</a>
                        </div>
                    );
                    break;
                case `image`:
                    result.push(
                        <div className="w-100 image-div" >
                            <img
                                onClick={() => handlePreviewImage(arr[i].url)}
                                src={arr[i].url}
                                alt={arr[i].url}
                                style={{ ...styleCheck(arr[i]), cursor: "pointer" }}
                                className="image-mobile"
                            />
                        </div>
                      );
                    break;
                // case `accordion`:
                //     const children = _RenderPreview(arr[i].accordion);
                //     const rgn = Math.random();
                //     const wrapper = (
                //         <div className="mock-accordion">
                //             <div>
                //                 <input type="checkbox" id={'section' + rgn} className="mock-accordion-input" />
                //                 <label htmlFor={'section' + rgn} className="mock-accordion-label">
                //                     {arr[i].text}
                //                 </label>
                //                 <div className="mock-accordion-content">{children}</div>
                //             </div>
                //         </div>
                //     );
                //     result.push(<div className="w-100">
                //         <div className="w-100" style={styleCheck(arr[i])}>
                //             {wrapper}
                //         </div>
                //     </div>);
                //     break;
            
                default:
                    break;
            }
        }
        return result
    }

    return <>
        {previewOpen && (
            <Image
                wrapperStyle={{
                    display: 'none',
                }}
                preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) => setPreviewOpen(visible),
                    afterOpenChange: (visible) =>
                        !visible && setPreviewOpen(false),
                }}
                src={previewOpen}
            />
        )}
        <div className="w-100">
            {contentDroppableAreaRender && contentDroppableAreaRender}
            {/* {dataLang && dataLang.layout ? _RenderPreview(dataLang && dataLang.layout ? dataLang.layout : []) : ''} */}
        </div>

    </>
}

export default DragInputTextViewEditView;