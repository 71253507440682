import React from 'react';
import Card from 'react-bootstrap/Card';
import IphoneContent from "../../assets/images/iphone14.png";
import SubmitIframeTextDragView from './SubmitIframeTextDragView';

function FormsPreviewPhoneTextDragComponentView({ data, handleDetail, handleCopy, isDownloadNo, isPrintNo, isDevice,
    contentDroppableAreaRender,
 }) {
    
    let _RenderImage = (qrCodeRef1, isText) => {
        return (
            <div className='w-100 card-mob card-mobile p-1'
                style={{
                    // overflow: "auto",
                    // borderRadius: "1rem",
                    // height: "100%",
                    // maxHeight: "560px"
                    // // maxHeight: "560px 563px"
                }}
                ref={qrCodeRef1 ? qrCodeRef1 : null}
            >
                {/* <div className='mt-2 text-center'>
                    <p>Froms</p>
                </div>
                <SubmissionFormPage 
                    isPreview={true}
                    labeEventName={data && data.eventName ? data.eventName : ""}
                /> */}
                <SubmitIframeTextDragView contentDroppableAreaRender={contentDroppableAreaRender} isDevice={isDevice} data={data} number={data && data.eventNumber ? data.eventNumber : ""} title={data && data.eventName ? data.eventName : ""} formLink={data && data.formLink ? data && data.formLink : ""} />
            </div>
        );
    }

    return (
        <div className='w-100'>
            <Card 
                style={{
                    backgroundImage: `url(${IphoneContent})`,
                }}
                className='div-phone'
            >
                <Card.Body
                style={{
                    width: "325px",
                    height: "100%",
                    margin: "auto",
                    padding: "3rem 0"
                }}
                >
                    {_RenderImage(null, "")}
                </Card.Body>
            </Card>
        </div>
    );
}

export default FormsPreviewPhoneTextDragComponentView;
