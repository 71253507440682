import React, { useEffect, useRef, useState } from "react";
import MagazineHeader from "./MagazineHeader";
import MagazineTabs from "./TableListMagazine";
import { MAGAZINES_URL } from "../../api/URLs";
import { deleteDataRequest, getDataRequest, putDataRequest } from "../../api/serviceMethods";
import MessageConfirmModal from "../../components/messageModal/MessageConfirmModal";
import { useTranslation } from "react-i18next";
import { message } from "antd";

const MagazinePage = () => {
  const [viewType, setViewType] = useState('table');
  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [magazineData, setMagazineData] = useState([])
  const messageModalRef = useRef('')
  const [dates, setDates] = useState([null, null]);
  const { t } = useTranslation();
  const [tableParams, setTableParams] = useState({
      pagination: {
          current: 1,
          pageSize: 10,
          total: 0,
      },
  });

  const initLoadMagazine = async () => {
    setLoading(true);
    await getDataRequest(`${MAGAZINES_URL}`, {
        keyword: keyword.trim(),
        startDate: dates[0] || "",
        endDate: dates[1] || "",
        sortKeys: { [tableParams.sortField]: tableParams.sortOrder === "ascend" ? "asc" : "desc" },//descend
        pageNo: tableParams.pagination.current,
        pageSize: tableParams.pagination.pageSize,
    }).then(res => {
        setLoading(false);
        if (res.status === 200) {
            setMagazineData(res?.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: res.total,
                },
            });
        }
    }).catch((err) => {
        setLoading(false);
        console.log("Error", err);
    });
};
const [isSearch, setIsSearch] = useState(true)
useEffect(() => {
  isSearch && initLoadMagazine();
}, [
  tableParams.pagination?.current,
  tableParams.pagination?.pageSize,
  tableParams?.sortOrder,
  tableParams?.sortField, isSearch,
  JSON.stringify(tableParams.filters), viewType]);

  const insertParams = (dataObject) => {
    const memberPayload = {
      previewFile: {
        url: dataObject&& dataObject.privateFile && dataObject.privateFile.url,
        size: dataObject&& dataObject.privateFile && dataObject.privateFile.size,
        fileName: dataObject&& dataObject.privateFile && dataObject.privateFile.fileName,
        page: dataObject&& dataObject.privateFile && dataObject.privateFile.page,
      },
      status: !dataObject.status
    }
    return memberPayload
  }
  const hadleUpdateMagazine = async (item) => {
    try {
      const memberPayload = insertParams(item)
      setLoading(true);
      await putDataRequest(`${MAGAZINES_URL}/${item.magazineId} `, memberPayload)
        .then((res) => {
          if (res.status === 200) {
            initLoadMagazine()
            messageModalRef.current.showSuccessConfirmsAutoClose(res && res.message, () => { }, "", true);
            setTimeout(() => {
              setLoading(false);
            }, 2000);
          } else {
            messageModalRef.current.showWarningConfirmsAutoClose(res && res.message, () => { }, "", true);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          messageModalRef.current.showWarningConfirmsAutoClose(err && err.response && err.response.data && err.response.data.message, () => { }, "", true);
        });
    } catch (error) {
      setLoading(false);
      messageModalRef.current.showWarningConfirmsAutoClose(`Server Error!`, () => { }, "", true);
    }
  }

  const deleteMagazineData = async (item) => {
    messageModalRef.current.showWarningConfirm(t('Are you sure you want to delete this magazine ?'), async () => {
      setLoading(true)
      await deleteDataRequest(`${MAGAZINES_URL}/${item.magazineId}`).then((res) => {
        messageModalRef.current.showSuccessConfirmsAutoClose(res && res.message, () => { }, "", true);
        initLoadMagazine();
        setLoading(false)
      }).catch((err) => {
        message.error("Error", err)
        setLoading(false)
      });
    }, "", true);
  };

  return (
    <div className="box-shadow h-100 w-100">
      <div className="w-100 h-100 overflow-auto">
        <div className="w-100">
          <div className="w-100 mt-3" style={{ padding: "0px 25px" }}>
            <MagazineHeader 
              viewType={viewType} setViewType={setViewType} 
              modalOpen={modalOpen} setModalOpen={setModalOpen}
              magazineData={magazineData} tableParams={tableParams} 
              setTableParams={setTableParams} loading={loading} 
              setLoading={setLoading} dates={dates} setDates={setDates}
              initLoadMagazine={() => {
                setIsSearch(false)
                setTimeout(() => {
                  setIsSearch(true)
                }, 100);
              }} 
              setKeyword={setKeyword}
              messageModalRef={messageModalRef}
            />
            <MagazineTabs
              viewType={viewType} setViewType={setViewType} 
              modalOpen={modalOpen} setModalOpen={setModalOpen}
              magazineData={magazineData} tableParams={tableParams} 
              setTableParams={setTableParams} loading={loading} 
              setLoading={setLoading} hadleUpdateMagazine={hadleUpdateMagazine}
              deleteMagazineData={deleteMagazineData}
            />
          </div>
        </div>
      </div>
      <MessageConfirmModal textCentered ref={messageModalRef} />
    </div>
  )
}

export default MagazinePage