import React from "react";
import { Avatar, Button, Input } from "antd";
import TableFetchData from "../../components/data-table/TableFetchData";
import { useTranslation } from "react-i18next";
import DateRangeComponent from "../../components/date/DateRangeComponent";
import emptyImage from "../../assets/images/image.jpeg"
import { formatDateTime } from "../../utils/dateFormatUtils";
import SearchSvg from "../../assets/images/SearchSvg";

const TableMember = ({
    loading,
    setLoading,
    memberData,
    keyword,
    setKeyword,
    tableParams,
    setTableParams,
    dates,
    setDates,
    initLoadMember,
    handleClickEditPro
}) => {
    const { t } = useTranslation();

    function formatDuration(input) {
        if (!input) return "0天";
        const { y = 0, m = 0, d = 0 } = input;

        if (y > 0) return `${y}年`;
        if (m > 0 && d > 0) return `${m}个月 ${d}天`;
        if (m > 0) return `${m}个月`;
        if (d > 0) return `${d}天`;
        return "0天";
    }

    const tableSettings = {
        appendable: false,
        removable: false,
        bordered: true,
        size: "small",
        pagination: {
            showLessItems: true,
            showSizeChanger: false,
            pageSize: 10,
        },
        columns: [
            {
                title: <label className="titleCard14400">{t('User Avatar')}</label> ,
                render: (_, member) => (
                    <Avatar
                        src={member && member.picUrl ? member.picUrl : emptyImage}
                        style={{
                            height: "35px",
                            width: "35px"
                        }}
                    />
                ),
            },
            {
                title: <label className="titleCard14400">{t('User Id')}</label>,
                render: (_, member) => (
                    <label className="text-capitalize">{member && member.memberId}</label>
                ),
            },
            {
                title: <label className="titleCard14400">{t('Phone Number')}</label>,
                render: (_, member) => (
                    <div className="d-flex">
                        <label className="text-capitalize">
                            {`(${member && member.phone && member.phone.countryCode})`} {member && member.phone && member.phone.number}
                        </label>
                    </div>
                ),
            },
            {
                title: <label className="ant-table-column-title titleCard14400">{t('Member Activation Time')}</label>,
                dataIndex: 'activatedTS',
                sorter: true,
                key: 'activatedTS',
                render: (_, record) => (
                    <>
                        {record.activatedTS ? formatDateTime(record.activatedTS) : ""}
                    </>
                )
            },
            {
                title: <label className="ant-table-column-title titleCard14400">{t('Member Expiration Time')}</label>,
                dataIndex: 'expiredTS',
                sorter: true,
                key: 'expiredTS',
                render: (_, record) => (
                    <>
                        {record.expiredTS ? formatDateTime(record.expiredTS) : ""}
                    </>
                )
            },
            {
                title: <label className="titleCard14400">{t('Membership Years')}</label>,
                render: (_, record) => (
                    <label className="text-capitalize">
                        {formatDuration(record && record.activePeriod)}
                    </label>
                )
            },            
            {
                title: <label className="ant-table-column-title titleCard14400">{t('Last Login Time')}</label>,
                dataIndex: 'lastLogin',
                sorter: true,
                key: 'lastLogin',
                render: (_, record) => (
                    <>
                        {record.lastLogin ? formatDateTime(record.lastLogin) : ""}
                    </>
                )
            },
            {
                title: <label className="titleCard14400">{t('Operator')}</label>,
                render: (_, member) => (
                    <div className="d-flex" style={{ cursor: "pointer", color: "#0075ff" }} onClick={() => handleClickEditPro(member)}>
                        {t("Details")}
                    </div>
                ),
            },
        ],
    };
    
    return (
        <>
            <div className="w-100" style={{ padding: "0px 25px" }}>
                <div className="d-flex justify-content-between mb-3">
                    <div className="d-flex align-items-center">
                        <Input
                            placeholder={t("Search")}
                            prefix={<SearchSvg style={{ color: '#8c8c8c' }} />}
                            size="large"
                            disabled={loading}
                            loading={loading}
                            className="me-4 col-6"
                            allowClear
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                        />
                        <DateRangeComponent
                            className="col-6"
                            placeholder={t("Please select a time or time period")}
                            disabled={loading}
                            value={dates ? dates : [null, null]}
                            setValue={setDates}
                            style={{ width: "50%" }}
                            size="large"
                        />
                    </div>
                    <div>
                        <Button
                            size="large"
                            onClick={() => {
                                setTableParams({
                                    ...tableParams,
                                    pagination: {
                                        ...tableParams.pagination,
                                        current: 1,
                                    },
                                });
                                initLoadMember();
                            }}
                        >
                            {t('Query')}
                        </Button>

                    </div>
                </div>
            </div>
            <div className="w-100" style={{ padding: "0px 25px" }}>
                <TableFetchData
                    tableParams={tableParams}
                    setTableParams={setTableParams}
                    data={memberData}
                    columns={tableSettings.columns}
                    loading={loading}
                    setLoading={setLoading}
                />
            </div>
        </>
    );
};

export default TableMember;
