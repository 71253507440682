import React from 'react';
import { LoadingOutlined, SmileOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons';
import { Divider, Steps } from 'antd';
import { TickSvg } from '../../assets/images/TickSvg';
import { useTranslation } from 'react-i18next';
const StepsComponent = ({setps=[]}) => {
    const { t } = useTranslation()
    // let setps = [
    //     {
    //         title: t('Basic information settings'),
    //         status: "done",//done fail warning, processing
    //         stepCount: 1,
    //     },
    //     {
    //         title: t('Edit information content'),
    //         status: "processing",
    //         stepCount: 2,
    //     },
    // ]

    return <>
        <div className='d-flex align-items-center'>
            {
                setps && setps.map((it, i) => (
                    <>
                    <div className='d-flex align-items-center'>
                        {
                            it.status === "toDo" && <div className='div-step'>{i + 1}</div>
                        }
                        {
                            it.status === "processing" && <div className='div-step div-step-active'>{i + 1}</div>
                        }
                        {
                            it.status === "done" &&  <TickSvg />
                        }
                        {
                            it.status === "fail" &&  <TickSvg />
                        }
                        {
                            it.status === "warning" &&  <TickSvg />
                        }
                        <div className='div-step- ps-2 pe-2-'>{it.title}</div>
                    </div>
                    {
                        i + 1 < setps.length ? <div className='d-flex align-items-center'>
                        <div className='div-step- ms-3 me-3' style={{minWidth: "50px"}}>
                            <Divider
                                style={{
                                    borderColor: '#ECECEF',
                                }}
                                className='w-100'
                            >
                            </Divider>
                        </div>
                    </div> : ""
                    }
                    {/* <div className='d-flex align-items-center'>
                        <div className='div-step'>2</div>
                        <div className='div-step- ps-2 pe-2-'>{t('Edit information content')}</div>
                    </div> */}
                    </>
                ))
            }
        </div>
    </>
}
export default StepsComponent;