import * as React from 'react';
import zoomScrollImg from "./zoomScroll.png";
import zoomCorner from "./zoomCorner.png";

const ZoomScrollImage = ({width, height, className=""}) => {
    return (
        <img
            src={zoomScrollImg}
            alt={""}
            style={{ 
                width: width ? width : "16px",
                height: height ? height : "16px",
             }}
            className={`${className}`}
        />
    );
}

export const ZoomCornerScrollImage = ({width, height, className=""}) => {
    return (
        <img
            src={zoomCorner}
            alt={""}
            style={{ 
                width: width ? width : "10px",
                height: height ? height : "10px",
             }}
            className={`${className}`}
        />
    );
}

export default ZoomScrollImage;